@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
/****colors*****/
.global_overlay, .checkbox_wrapper span:after, .accordion .accordion_item header span:after, .accordion .accordion_item header span:before, .prompt .close:after, .prompt .close:before, .point b:after, .point b:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute; }

.checkbox_wrapper input[type="radio"] + span, .checkbox_wrapper input[type="radio"] + span:after, .color_item, .color_filter.checked:after, .cou .slick-arrow, .point, .point b {
  border-radius: 100%; }

.global_overlay {
  opacity: 0.9;
  background: #E9EAEC;
  position: fixed;
  z-index: 9; }

.cross_close:after, .cross_close:before, .burger div, .burger div:after, .burger div:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 2px;
  width: 24px;
  background: #40454B;
  transition: all 0.2s ease-in-out; }

@font-face {
  font-family: 'Bebas';
  src: url("../fonts/bebasneue_light-webfont.eot");
  src: url("../fonts/bebasneue_light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/bebasneue_light-webfont.woff2") format("woff2"), url("../fonts/bebasneue_light-webfont.woff") format("woff"), url("../fonts/bebasneue_light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Bebas';
  src: url("../fonts/subset-BebasNeueRegular.eot");
  src: url("../fonts/subset-BebasNeueRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-BebasNeueRegular.woff2") format("woff2"), url("../fonts/subset-BebasNeueRegular.woff") format("woff"), url("../fonts/subset-BebasNeueRegular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Bebas';
  src: url("../fonts/subset-BebasNeueBold.eot");
  src: url("../fonts/subset-BebasNeueBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-BebasNeueBold.woff2") format("woff2"), url("../fonts/subset-BebasNeueBold.woff") format("woff"), url("../fonts/subset-BebasNeueBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Pragmatica';
  src: url("../fonts/pragmatica-book-webfont.eot");
  src: url("../fonts/pragmatica-book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/pragmatica-book-webfont.woff2") format("woff2"), url("../fonts/pragmatica-book-webfont.woff") format("woff"), url("../fonts/pragmatica-book-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Pragmatica';
  src: url("../fonts/pragmatica-bold-webfont.eot");
  src: url("../fonts/pragmatica-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/pragmatica-bold-webfont.woff2") format("woff2"), url("../fonts/pragmatica-bold-webfont.woff") format("woff"), url("../fonts/pragmatica-bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

body {
  line-height: 26px;
  font-size: 17px;
  color: #40454B;
  font-family: 'Pragmatica', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased; }
  @media screen and (max-width: 414px) {
    body {
      font-size: 16px; } }

h1, .title, h2, h3 {
  font-family: "Bebas";
  font-weight: normal;
  font-size: 70px;
  line-height: 64px;
  max-width: 860px;
  margin: 0; }

h1, .title {
  padding-top: 50px; }
  @media screen and (max-width: 1024px) {
    h1, .title {
      font-size: 40px;
      line-height: 36px;
      padding-top: 10px;
      margin-bottom: 24px; } }

h2 {
  font-size: 30px;
  line-height: 30px;
  padding-top: 10px; }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px;
      line-height: 30px;
      padding-top: 20px;
      margin-bottom: 20px; } }

h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin: 0; }

.product_options h1 {
  padding-top: 10px; }

p {
  max-width: 860px;
  margin: 24px 0; }

.p_min {
  font-size: 14px;
  line-height: 22px; }

b, strong {
  font-weight: bold; }

a {
  color: #E51C2F;
  transition: all 0.2s ease-in-out; }
  a:hover {
    text-decoration: none;
    color: #1B1B1B; }

.danger {
  color: #FD7D08; }

.success {
  color: #00B172; }

.disable {
  color: #C0C0C0; }

.text_min {
  font-size: 16px; }

blockquote {
  max-width: 860px;
  margin: 90px 0;
  padding: 4px 0 0 24px;
  box-sizing: border-box;
  font-size: 30px;
  line-height: 30px;
  border-left: 2px solid #000;
  font-family: "Bebas";
  font-weight: bold; }

ul {
  padding: 0;
  list-style: none;
  max-width: 860px; }
  ul h2 {
    margin: 0;
    padding: 0; }
    ul h2 + p {
      margin-top: 8px; }
  ul li {
    margin: 20px 0;
    padding: 0;
    padding-left: 27px;
    position: relative; }
    ul li:before {
      content: '';
      top: 1px;
      position: absolute;
      height: 16px;
      background: #E51C2F;
      width: 7px;
      left: 0;
      transform: skew(-24deg); }

input, textarea {
  border-radius: 0 !important; }
  input::placeholder, textarea::placeholder {
    color: #B2B2B2; }

button {
  border: none;
  background: transparent;
  transition: all 0.2s ease-in-out; }

.btn {
  padding: 20px 24px; }

.btn.mid, .btn.mid:after, .btn.mid:before {
  padding: 14px 22px;
  font-size: 12px; }

.btn.min, .btn.min:after, .btn.min:before {
  padding: 4px 19px;
  font-size: 12px; }

.btn {
  color: #b91525;
  display: inline-flex;
  border: 1px solid #E51C2F;
  font-size: 14px;
  line-height: 25px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  background: none;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
  font-weight: bold; }
  .btn.primary {
    background: #E51C2F;
    color: #fff;
    padding: 0;
    padding-left: 24px;
    align-items: center; }
    .btn.primary:hover {
      background: #8b101c; }
    .btn.primary:after {
      content: '';
      background: url(../img/arrow-g.svg) center center no-repeat;
      height: 55px;
      width: 55px;
      padding: 0;
      margin-left: 24px;
      background-color: #b91525; }
  .btn.max {
    display: block;
    width: 100%; }
  .btn.dark_theme {
    border-color: #fff;
    color: #fff; }

.button_link {
  display: inline-flex;
  color: #E51C2F;
  padding: 15px 24px;
  padding-left: 0;
  padding-right: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-transform: uppercase; }
  .button_link:after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 2px;
    background: #E51C2F;
    width: 100%;
    transition: all 0.2s ease-in-out; }
  .button_link:hover {
    color: #1B1B1B; }

.checkbox_wrapper {
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  padding: 0 0 0 28px;
  position: relative;
  margin: 8px 0; }
  .checkbox_wrapper span {
    border: 1px solid #B2B2B2;
    width: 18px;
    height: 18px;
    display: block;
    position: absolute;
    left: 0;
    top: -1px;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.2s;
    background: #fff; }
    .checkbox_wrapper span:after {
      content: '';
      background-color: #E51C2F;
      width: 18px;
      height: 18px;
      opacity: 0; }
  .checkbox_wrapper.disabled {
    color: #C0C0C0; }
    .checkbox_wrapper.disabled:hover span {
      background: #fff; }
  .checkbox_wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box; }
    .checkbox_wrapper input:checked + span {
      border-color: #E51C2F; }
      .checkbox_wrapper input:checked + span:after {
        opacity: 1; }
    .checkbox_wrapper input[type="checkbox"] + span:after {
      background-image: url(../img/check.svg);
      background-position: center;
      background-repeat: no-repeat; }
    .checkbox_wrapper input[type="radio"] + span:after {
      transform: scale(0.6); }
  .checkbox_wrapper:hover span {
    background: #EAEAEA; }

input, textarea {
  padding: 28px 24px 12px;
  line-height: 20px;
  border: none;
  font-size: 18px;
  outline: none; }

textarea {
  height: 110px;
  margin: 0;
  display: block; }

.input_wrapper {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  background: #fff;
  border: 1px solid #B2B2B2; }
  .input_wrapper input:focus + div, .input_wrapper textarea:focus + div, .input_wrapper.active input + div,
  .input_wrapper.active textarea + div {
    transform: translateY(20px);
    opacity: 0; }
  .input_wrapper input:focus ~ span, .input_wrapper textarea:focus ~ span, .input_wrapper.active input ~ span,
  .input_wrapper.active textarea ~ span {
    transform: none;
    opacity: 1;
    transition-delay: 0.2s; }
  .input_wrapper div, .input_wrapper span {
    transition: all 0.2s ease-in-out;
    color: #606060; }
  .input_wrapper span {
    position: absolute;
    display: block;
    top: 2px;
    left: 24px;
    transform: translateY(-10px);
    opacity: 0;
    z-index: 1;
    font-size: 12px; }
  .input_wrapper input, .input_wrapper textarea {
    background: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    z-index: 2;
    position: relative;
    transition: all 0.2s ease-in-out; }
    .input_wrapper input:focus, .input_wrapper textarea:focus {
      box-shadow: 0 0 10px 0 rgba(229, 28, 47, 0.2);
      border-color: #E51C2F; }
  .input_wrapper .input_value {
    position: absolute;
    top: 0;
    padding: 20px 24px;
    line-height: 20px; }
  .input_wrapper.error {
    color: #FF3C0F;
    background: #FFF3F1;
    border-color: #FF3C0F; }
    .input_wrapper.error input {
      border-color: #FF3C0F;
      color: #FF3C0F; }

.error_message {
  background: #FF3C0F;
  color: #fff !important;
  padding: 0 24px;
  font-size: 16px; }

.select_wrapper {
  font-size: 16px;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin-bottom: 20px; }
  .select_wrapper.active {
    z-index: 999; }
    .select_wrapper.active .select_dropdown {
      display: block; }
    .select_wrapper.active .select_form {
      border-color: #E51C2F; }
      .select_wrapper.active .select_form:after {
        transform: rotate(-90deg); }
  .select_wrapper:hover .select_form {
    border-color: #E51C2F; }
  .select_wrapper .select_form {
    border: 1px solid #B2B2B2;
    padding: 20px 24px;
    padding-right: 44px;
    cursor: pointer;
    position: relative;
    background: #fff;
    transition: all 0.2s ease-in-out; }
    .select_wrapper .select_form:after {
      content: '';
      background: url(../img/arrow-r.svg) center no-repeat;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 17px;
      width: 20px;
      text-align: center;
      transform: rotate(90deg); }
  .select_wrapper .select_dropdown {
    box-shadow: 0 20px 40px 0 rgba(120, 107, 97, 0.12);
    border: 1px solid #B2B2B2;
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    background: #fff;
    animation-name: select_dd;
    animation-duration: 0.3s;
    min-width: 180px;
    max-height: 300px;
    overflow-x: scroll; }
    .select_wrapper .select_dropdown .option {
      padding: 10px 20px;
      cursor: pointer;
      display: flex; }
      .select_wrapper .select_dropdown .option:hover {
        background: #EAEAEA; }

@keyframes select_dd {
  0% {
    opacity: 0;
    transform: translateY(40px); } }
  .select_wrapper .color_item {
    transform: translateY(1px); }

.pagination {
  display: flex;
  align-items: stretch; }
  .pagination .pagination_btn {
    padding: 0px 20px;
    border: 1px solid #B2B2B2;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: #40454B;
    font-size: 14px;
    transition: all 0.2s ease-in-out; }
    .pagination .pagination_btn:hover {
      background: #EAEAEA; }
  .pagination .select_wrapper {
    margin: 0 -1px; }
  .pagination .select_dropdown {
    min-width: auto; }

.color_item {
  height: 16px;
  width: 16px;
  min-width: 16px !important;
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px 0 0; }

.color_filter {
  cursor: pointer;
  position: relative; }
  .color_filter.checked:after {
    content: '';
    position: absolute;
    border: 1px solid #1B1B1B;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px; }

.social_link {
  display: inline-block;
  padding: 10px;
  opacity: 0.4;
  margin: 20px 0 0 0;
  transition: all 0.2s ease-in-out; }
  .social_link svg {
    fill: #E51C2F;
    width: 16px;
    height: 16px;
    display: block; }
  .social_link:hover {
    opacity: 1; }

@keyframes modal_overlay {
  from {
    opacity: 0; } }

.modal {
  position: fixed;
  top: 0;
  z-index: 10;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch; }
  .modal .modal_content_wrapper {
    width: 100%;
    margin: auto; }
  .modal.active {
    display: flex;
    align-items: stretch;
    justify-content: flex-end; }
    .modal.active.center .cross_close {
      position: absolute; }
    .modal.active.center .modal_content {
      margin: 0 6%; }

.modal_content {
  text-align: left;
  background: #fff;
  padding: 0 50px 50px;
  overflow: hidden;
  min-height: 100%;
  min-width: 100px;
  height: inherit;
  box-sizing: border-box;
  animation: modal_content 1.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1); }
  .modal_content header {
    padding: 30px 0; }
    .modal_content header .title {
      padding: 20px 0; }
  @media screen and (max-width: 414px) {
    .modal_content {
      padding: 0 4%; }
      .modal_content header .title {
        padding: 0; }
      .modal_content .btn {
        width: 100%; } }

@keyframes modal_content {
  0% {
    transform: translateX(20px) scale(0.95);
    opacity: 0; } }

.cross_close {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer; }
  .cross_close:after {
    transform: rotate(45deg);
    animation: close_1 0.6s ease-out; }
  .cross_close:before {
    transform: rotate(-45deg);
    animation: close_2 0.7s ease-out; }

@keyframes close_1 {
  0% {
    opacity: 0; }
  50% {
    transform: rotate(-45deg);
    opacity: 0; } }

@keyframes close_2 {
  0% {
    opacity: 0; }
  70% {
    transform: rotate(-135deg);
    opacity: 0; } }
  @media screen and (max-width: 414px) {
    .cross_close {
      width: 12%;
      height: 65px; } }

.accordion .accordion_item header {
  background: #EAEAEA;
  padding: 10px 20px 10px 30px;
  position: relative;
  cursor: pointer;
  margin-bottom: 2px; }
  .accordion .accordion_item header span {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto; }
    .accordion .accordion_item header span:after, .accordion .accordion_item header span:before {
      content: '';
      width: 8px;
      height: 1px;
      position: absolute;
      background: #E51C2F; }
    .accordion .accordion_item header span:before {
      transform: rotate(90deg); }

.accordion .accordion_item.active span:before {
  display: none; }

.accordion .accordion_item.active .accordion_item_content {
  display: block; }

.accordion .accordion_item .accordion_item_content {
  padding: 10px 30px;
  padding-bottom: 14px;
  display: none; }

.table_wrapper {
  overflow-x: scroll; }

table {
  width: 100%;
  border-collapse: collapse; }
  table thead {
    background: #40454B;
    font-size: 16px;
    color: #fff; }
  table td {
    padding: 10px 30px;
    border-bottom: 1px solid #DADBEE; }
  table .disabled {
    color: #C0C0C0; }

.cell_r {
  text-align: right; }

.cell_c {
  text-align: center; }

.order_history_table tr {
  cursor: pointer; }

.status_danger {
  color: #FD7D08; }

.status_success {
  color: #00B172; }

.gdpr {
  position: fixed;
  bottom: 0;
  background: #E51C2F;
  width: 100%;
  z-index: 5;
  color: #B2B2B2;
  font-size: 14px;
  line-height: 20px;
  animation-name: gdpr;
  animation-duration: 2s;
  display: none; }
  .gdpr .content {
    padding: 0px 6%; }
  .gdpr p {
    margin: 20px 0; }

@keyframes gdpr {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(100%); } }

.tabs_wrapper {
  display: flex;
  flex-direction: column;
  background: #fff; }
  .tabs_wrapper .tabs_header {
    display: flex;
    border-bottom: 1px solid #DADBEE; }
    @media screen and (max-width: 768px) {
      .tabs_wrapper .tabs_header {
        overflow-x: scroll;
        overflow-y: hidden; } }
    .tabs_wrapper .tabs_header .tab_link {
      transition: all 0.2s ease-in-out;
      display: flex;
      padding: 10px 0;
      margin: 0 24px 0 0;
      margin-bottom: -1px;
      cursor: pointer;
      color: #1B1B1B;
      text-transform: uppercase;
      font-size: 12px;
      position: relative;
      line-height: 16px;
      align-items: flex-end; }
      .tabs_wrapper .tabs_header .tab_link:after {
        content: '';
        background: #E51C2F;
        height: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute; }
      .tabs_wrapper .tabs_header .tab_link.active {
        color: #E51C2F; }
        .tabs_wrapper .tabs_header .tab_link.active:after {
          height: 1px;
          transition: all 0.2s ease-in-out; }
  .tabs_wrapper .tabs_content_item {
    display: none;
    padding: 20px 0; }
    .tabs_wrapper .tabs_content_item.active {
      display: block; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li:before {
      display: none; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  height: 100%; }
  body.no_scroll {
    overflow-y: hidden;
    height: 100%; }

html {
  height: 100%; }

.into {
  padding: 0 6%;
  box-sizing: border-box; }
  @media screen and (max-width: 414px) {
    .into {
      padding: 0 3%; } }
  .into.small {
    margin: auto; }
    .into.small h1, .into.small h2, .into.small h3, .into.small ul, .into.small ol, .into.small p {
      max-width: 860px;
      margin-left: auto;
      margin-right: auto; }

.col_2 {
  width: 50%; }
  @media screen and (max-width: 414px) {
    .col_2 {
      width: 100%; } }

.col_3 {
  width: 33.33333%; }

.col_3x2 {
  width: 66.66666%; }

.col_4 {
  width: 25%; }
  @media screen and (max-width: 414px) {
    .col_4 {
      width: 46%; } }

.col_4x3 {
  width: 75%; }

@media screen and (max-width: 414px) {
  .col_3 {
    width: 100%; } }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.img_wr img {
  display: block;
  width: 100%; }

.header {
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(97, 117, 132, 0.1);
  display: flex;
  justify-content: space-between;
  height: 84px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 4; }
  @media screen and (max-width: 768px) {
    .header {
      height: auto; } }
  .header .logo {
    height: 26px;
    width: 138px;
    display: block;
    margin: auto;
    fill: #E51C2F; }
  .header .left {
    margin-left: 6%;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .header .left {
        margin-left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between; } }
    .header .left:after {
      content: '';
      height: 100%;
      width: 1px;
      background: #E9EAEC;
      transform: skew(-24deg); }
      @media screen and (max-width: 1024px) {
        .header .left:after {
          display: none; } }
    @media screen and (max-width: 1024px) {
      .header .left .burger {
        margin: 0; }
      .header .left a {
        position: absolute;
        display: block;
        left: 60px;
        right: 60px;
        margin: auto; } }
  .header .right {
    display: flex; }
    @media screen and (max-width: 1024px) {
      .header .right {
        display: none; } }
  .header .search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px 0 30px; }
    @media screen and (max-width: 1024px) {
      .header .search {
        display: none; } }
    .header .search input {
      padding: 32px 20px 30px 36px;
      font-size: 14px;
      display: block;
      flex: 1 auto;
      background: url(../img/search_2.svg) left center no-repeat; }
  .header .lang {
    font-size: 12px;
    font-weight: bold;
    color: #9BA1A7;
    display: flex;
    align-items: center;
    padding: 0 64px 0 34px;
    column-gap: 12px;
    position: relative; }
    .header .lang a {
      padding: 10px; }
    .header .lang:before {
      content: '';
      transform: skew(-24deg);
      position: absolute;
      top: 0;
      bottom: 0;
      background: #E9EAEC;
      width: 1px;
      left: -22px; }
  .header .right_btn {
    background: #E51C2F;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-weight: bold;
    position: relative; }
    .header .right_btn:before {
      content: '';
      background: #E51C2F;
      width: 40px;
      transform: skew(-24deg);
      position: absolute;
      left: -18px;
      top: 0;
      bottom: 0;
      transition: all 0.2s ease-in-out; }
    .header .right_btn:hover {
      background: #8b101c; }
      .header .right_btn:hover:before {
        background: #8b101c; }
    .header .right_btn b {
      z-index: 2;
      white-space: nowrap; }
  .header a {
    font-size: 13px;
    text-decoration: none; }
  .header .button_link {
    font-size: 11px;
    line-height: 1; }
  .header .search_link {
    width: 60px;
    height: 60px;
    background: url(../img/search_2.svg) center no-repeat; }
    @media screen and (min-width: 1024px) {
      .header .search_link {
        display: none; } }

@media screen and (max-width: 414px) {
  .map_header {
    height: 50px; } }

.map_header .bread_crumbs {
  padding: 0;
  margin: 0 40px 0 0;
  width: 100px; }
  @media screen and (max-width: 1024px) {
    .map_header .bread_crumbs {
      margin: 0 0 0 6%; } }

@media screen and (max-width: 1024px) {
  .map_header .left {
    display: flex;
    height: 100%; } }

@media screen and (max-width: 1024px) {
  .map_header .left a {
    display: flex;
    position: relative; } }

@media screen and (max-width: 1024px) {
  .map_header .right {
    display: block;
    position: absolute;
    padding: 5px 0;
    top: 50px;
    background: #fff;
    border-top: 1px solid #E9EAEC;
    left: 0;
    right: 0; } }

.footer {
  background: #E51C2F;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 20px 0;
  position: relative; }
  .footer a {
    color: #fff; }
  .footer .into {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .footer .into {
        flex-direction: column;
        align-items: flex-start; } }
  .footer .icons {
    display: flex; }
    .footer .icons .social_link {
      opacity: 1;
      border: 1px solid #F94254;
      margin-top: 0;
      margin-right: -1px; }
      .footer .icons .social_link svg {
        fill: #fff; }
    .footer .icons #drive_link svg {
      width: 54px; }
  .footer nav {
    font-size: 11px;
    font-weight: bold;
    padding: 0;
    display: flex; }
    .footer nav a {
      text-transform: uppercase;
      text-decoration: none;
      padding: 2px 0;
      display: block;
      position: relative;
      margin-right: 20px; }
      .footer nav a:after {
        left: 0;
        content: '';
        bottom: 0;
        height: 1px;
        background: #fff;
        position: absolute;
        transition: all 0.2s ease-in-out;
        width: 0; }
      .footer nav a:hover:after {
        width: 100%; }
    @media screen and (max-width: 1024px) {
      .footer nav {
        order: 2;
        column-count: 3;
        column-gap: 40px;
        display: block;
        margin: 0; }
        .footer nav a {
          padding: 8px 0px; } }
    @media screen and (max-width: 414px) {
      .footer nav {
        column-gap: 30px; } }
  .footer .footer_bot {
    margin: 20px 0 0 0; }
  .footer .footer_n {
    text-align: right;
    opacity: 0.8; }
    .footer .footer_n a {
      text-decoration: none; }
    @media screen and (max-width: 1024px) {
      .footer .footer_n {
        margin-top: 16px;
        text-align: left; } }
  .footer .footer_logo svg {
    fill: #fff;
    height: 18px;
    width: 94px; }
  @media screen and (max-width: 1024px) {
    .footer .footer_logo {
      margin: 15px 0; } }
  .footer .dec {
    width: 2px;
    right: 30%;
    background: #fff;
    position: absolute;
    top: 0px;
    bottom: 0;
    transform: skew(-24deg); }
    .footer .dec:after {
      width: 30px;
      height: 50%;
      content: '';
      background: rgba(255, 255, 255, 0.4);
      display: block;
      transform: translateX(10px); }
    @media screen and (max-width: 1024px) {
      .footer .dec {
        bottom: auto;
        height: 90px;
        right: auto;
        left: 36%; } }

body.hidden-scroll {
  overflow: hidden; }

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #40454B;
  opacity: 0.9;
  display: none;
  z-index: 1035; }

.sl-wrapper {
  z-index: 1040; }
  .sl-wrapper * {
    box-sizing: border-box; }
  .sl-wrapper button {
    border: 0 none;
    background: transparent;
    font-size: 28px;
    padding: 0;
    cursor: pointer; }
    .sl-wrapper button:hover {
      opacity: 0.7; }
  .sl-wrapper .sl-close {
    display: none;
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 10060;
    margin-top: -14px;
    margin-right: -14px;
    height: 44px;
    width: 44px;
    line-height: 44px;
    font-family: Arial, Baskerville, monospace;
    color: #fff;
    font-size: 3rem; }
    .sl-wrapper .sl-close:focus {
      outline: none; }
  .sl-wrapper .sl-counter {
    display: none;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 1060;
    color: #fff;
    font-size: 1rem; }
  .sl-wrapper .sl-navigation {
    width: 100%;
    display: none; }
    .sl-wrapper .sl-navigation button {
      position: fixed;
      top: 50%;
      margin-top: -22px;
      height: 44px;
      width: 22px;
      line-height: 44px;
      text-align: center;
      display: block;
      z-index: 10060;
      font-family: Arial, Baskerville, monospace;
      color: #fff; }
      .sl-wrapper .sl-navigation button.sl-next {
        right: 5px;
        font-size: 2rem; }
      .sl-wrapper .sl-navigation button.sl-prev {
        left: 5px;
        font-size: 2rem; }
      .sl-wrapper .sl-navigation button:focus {
        outline: none; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-navigation button {
          width: 44px; }
          .sl-wrapper .sl-navigation button.sl-next {
            right: 10px;
            font-size: 3rem; }
          .sl-wrapper .sl-navigation button.sl-prev {
            left: 10px;
            font-size: 3rem; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-navigation button {
          width: 44px; }
          .sl-wrapper .sl-navigation button.sl-next {
            right: 20px;
            font-size: 3rem; }
          .sl-wrapper .sl-navigation button.sl-prev {
            left: 20px;
            font-size: 3rem; } }
  .sl-wrapper.sl-dir-rtl .sl-navigation {
    direction: ltr; }
  .sl-wrapper .sl-image {
    position: fixed;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 10000; }
    .sl-wrapper .sl-image img {
      margin: 0;
      padding: 0;
      display: block;
      border: 0 none;
      width: 100%;
      height: auto; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-image img {
          border: 0 none; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-image img {
          border: 0 none; } }
    .sl-wrapper .sl-image iframe {
      background: #000;
      border: 0 none; }
      @media (min-width: 35.5em) {
        .sl-wrapper .sl-image iframe {
          border: 0 none; } }
      @media (min-width: 50em) {
        .sl-wrapper .sl-image iframe {
          border: 0 none; } }
    .sl-wrapper .sl-image .sl-caption {
      display: none;
      padding: 10px;
      color: #fff;
      background: rgba(0, 0, 0, 0.8);
      font-size: 1rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
      .sl-wrapper .sl-image .sl-caption.pos-top {
        bottom: auto;
        top: 0; }
      .sl-wrapper .sl-image .sl-caption.pos-outside {
        bottom: auto; }
    .sl-wrapper .sl-image .sl-download {
      display: none;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #fff;
      z-index: 1060; }

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite; }

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.sl-transition {
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms; }

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0; }
  50% {
    opacity: 1; }
  100% {
    transform: scale(1.2);
    opacity: 0; } }

.ui_kit_container {
  padding: 0 10% 10%; }
  .ui_kit_container h2 {
    margin: 100px 0 40px 0;
    padding-top: 20px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.content {
  flex: 1 1 auto;
  margin-top: 84px; }
  @media screen and (max-width: 768px) {
    .content {
      margin-top: 56px; } }

.bread_crumbs {
  margin: 30px 0 0 0;
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 1024px) {
    .bread_crumbs {
      margin: 20px 0 10px; } }
  .bread_crumbs .into {
    display: flex;
    column-gap: 12px; }
  .bread_crumbs a {
    color: #6B727B;
    text-decoration: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px; }
    .bread_crumbs a:before {
      content: '';
      background: url(../img/arrow.svg);
      width: 5px;
      height: 14px;
      display: block; }

.burger {
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  margin: 0 24px 0 16px; }
  .burger div:after {
    top: -14px; }
  .burger div:before {
    bottom: -14px;
    left: -8px;
    width: 16px; }
  .burger:hover div {
    width: 12px; }

.ctn_dealers {
  background: #E51C2F url(../img/map.svg) no-repeat center;
  color: #fff;
  padding: 95px 0 30px;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .ctn_dealers {
      padding: 30px 0; } }
  .ctn_dealers header {
    border-bottom: 1px solid #F94254;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 50px; }
    @media screen and (max-width: 1024px) {
      .ctn_dealers header {
        display: block;
        padding: 0 0 20px 0; } }
    .ctn_dealers header p {
      margin: 0;
      width: 33.3333%; }
      @media screen and (max-width: 1024px) {
        .ctn_dealers header p {
          width: auto; } }
  .ctn_dealers .search_wrapper {
    margin: 60px auto;
    max-width: 860px;
    border: 2px solid #fff;
    padding: 6px;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .ctn_dealers .search_wrapper {
        margin: 20px auto; } }
    .ctn_dealers .search_wrapper input {
      width: 80%;
      padding: 0 0 0 60px;
      background: transparent;
      color: #fff;
      font-size: 14px;
      box-sizing: border-box;
      background: url(../img/search.svg) no-repeat 20px center; }
      .ctn_dealers .search_wrapper input::placeholder {
        color: #fff; }
      @media screen and (max-width: 414px) {
        .ctn_dealers .search_wrapper input {
          padding: 10px 10px 10px 60px;
          width: 100%; } }
    .ctn_dealers .search_wrapper .btn {
      margin: 0;
      background: #40454B;
      width: 20%;
      text-align: center;
      display: block;
      border: none;
      color: #fff; }
      .ctn_dealers .search_wrapper .btn:hover {
        background: black; }
      @media screen and (max-width: 414px) {
        .ctn_dealers .search_wrapper .btn {
          display: none; } }
  .ctn_dealers .dec {
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    left: 50%;
    height: 50px;
    width: 30px;
    transform: skew(-24deg); }
    .ctn_dealers .dec:after {
      content: '';
      height: 100px;
      width: 3px;
      background: #fff;
      position: absolute;
      left: -12px; }
    @media screen and (max-width: 414px) {
      .ctn_dealers .dec {
        left: 80%; } }

.ctn_support {
  background-position: top left;
  color: #fff;
  padding: 95px 0;
  border-top: 4px solid #2B2F35;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .ctn_support {
      padding: 20px 0; } }
  .ctn_support .support_pattern {
    background: #40454B url(../img/pattern1.png);
    background-size: 300px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1; }
  .ctn_support header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media screen and (max-width: 1024px) {
      .ctn_support header {
        display: block; } }
  .ctn_support .grid_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 2px;
    counter-reset: section; }
    @media screen and (max-width: 1024px) {
      .ctn_support .grid_wrapper {
        flex-direction: column; } }
    .ctn_support .grid_wrapper .item {
      background: #E9EAEC;
      font-family: "Bebas";
      color: #40454B;
      flex: auto;
      text-align: center;
      font-size: 24px;
      padding: 20px 0;
      line-height: 1;
      position: relative;
      overflow: hidden; }
      @media screen and (max-width: 1024px) {
        .ctn_support .grid_wrapper .item {
          margin: 0 0 2px 0; } }
      .ctn_support .grid_wrapper .item b {
        position: relative; }
      .ctn_support .grid_wrapper .item:before {
        counter-increment: section 1;
        content: "0" counter(section);
        font-weight: bold;
        position: absolute;
        left: -6%;
        top: -20px;
        font-size: 134px;
        color: #fff; }
  .ctn_support .support_contacts {
    display: flex;
    padding: 80px 0 0 0;
    column-gap: 40px; }
    @media screen and (max-width: 1024px) {
      .ctn_support .support_contacts {
        flex-direction: column; } }
    .ctn_support .support_contacts .item {
      line-height: 1;
      width: 33.333%;
      font-family: "Bebas";
      font-weight: bold;
      font-size: 24px; }
      @media screen and (max-width: 1024px) {
        .ctn_support .support_contacts .item {
          width: auto;
          margin-bottom: 20px; } }
      .ctn_support .support_contacts .item header {
        color: #9BA1A7; }
      .ctn_support .support_contacts .item .title {
        font-size: 50px;
        margin: 0;
        padding: 0;
        line-height: 1; }
      .ctn_support .support_contacts .item a {
        color: #fff;
        text-decoration: none; }

.ctn_cert {
  background: #E9EAEC;
  padding: 95px 0;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .ctn_cert {
      padding: 30px 0; } }
  .ctn_cert .column {
    width: 33.33333%;
    position: relative;
    z-index: 2;
    padding: 0 40px 0px 0;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) {
      .ctn_cert .column {
        width: auto;
        padding: 0; } }
  .ctn_cert .dec {
    position: absolute;
    background: #fff;
    opacity: 0.4;
    transform: skew(-24deg);
    width: 500px;
    top: 0;
    bottom: 0;
    left: 24%; }
  .ctn_cert .dec_2 {
    position: absolute;
    background: #fff;
    transform: skew(-24deg);
    width: 4px;
    bottom: 0;
    right: 34%;
    height: 160px; }
    .ctn_cert .dec_2:after {
      width: 32px;
      content: '';
      height: 40px;
      right: -40px;
      position: absolute;
      background: #fff;
      bottom: 0; }
  .ctn_cert .right {
    width: 66.6666%;
    min-height: 500px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .ctn_cert .right {
        width: auto;
        min-height: auto; } }
  .ctn_cert .into {
    display: flex;
    padding: 0;
    padding-left: 6%; }
    @media screen and (max-width: 1024px) {
      .ctn_cert .into {
        flex-direction: column;
        padding: 0 3%; } }
  .ctn_cert blockquote {
    position: relative;
    max-width: 100%;
    padding: 36px 0 30px;
    padding-right: 7.8%;
    padding-left: 44%;
    border: none;
    margin: 0; }
    @media screen and (max-width: 1024px) {
      .ctn_cert blockquote {
        padding: 60px 0 0 0;
        position: revert; } }
    .ctn_cert blockquote:after {
      content: '';
      left: 20%;
      right: -10%;
      top: 0;
      bottom: 0;
      background: #E51C2F;
      position: absolute;
      transform: skew(-24deg); }
      @media screen and (max-width: 1024px) {
        .ctn_cert blockquote:after {
          height: 200px;
          right: 15%;
          left: 15%; } }
    .ctn_cert blockquote:before {
      content: '';
      position: absolute;
      width: 2px;
      height: 80px;
      background: #E51C2F;
      transform: skew(-24deg);
      top: 0px;
      left: 20%; }
      @media screen and (max-width: 1024px) {
        .ctn_cert blockquote:before {
          left: 15%; } }
    .ctn_cert blockquote span {
      position: relative;
      z-index: 2;
      color: #fff; }
      @media screen and (max-width: 1024px) {
        .ctn_cert blockquote span {
          color: #40454B; } }
  .ctn_cert .cert {
    margin-top: 180px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .ctn_cert .cert {
        margin-top: 10px; } }
    .ctn_cert .cert .sl_item {
      position: absolute;
      left: 20%;
      top: 30px;
      display: block;
      border: 5px solid #40454B;
      background: #FFF;
      box-shadow: 0px 13px 27px 0px rgba(97, 117, 132, 0.2), 0px 2.7px 2.7px 0px rgba(0, 0, 0, 0.25) inset;
      z-index: 2;
      padding: 22px 16px;
      width: 20%;
      box-sizing: border-box; }
      @media screen and (max-width: 1024px) {
        .ctn_cert .cert .sl_item {
          left: auto;
          right: auto;
          width: auto;
          margin: auto 20%;
          max-width: 300px;
          position: relative; } }
      .ctn_cert .cert .sl_item img {
        display: block;
        width: 100%; }
  .ctn_cert.big {
    padding-bottom: 0; }
  .ctn_cert .bot {
    background: #E51C2F;
    width: 380px;
    padding: 40px;
    padding-left: 6%;
    color: #fff;
    z-index: 2;
    position: relative;
    margin-top: 90px; }
    @media screen and (max-width: 414px) {
      .ctn_cert .bot {
        padding: 30px;
        box-sizing: border-box; } }
    .ctn_cert .bot:after {
      position: absolute;
      top: 0;
      bottom: 0;
      content: '';
      width: 300px;
      transform: skew(-24deg);
      right: -80px;
      background: #E51C2F;
      z-index: -1; }

.ctn_about {
  position: relative;
  background: url(../img/bg_rad.png) no-repeat;
  background-position: -40% center;
  padding: 95px 0 120px;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .ctn_about {
      padding: 30px 0 120px;
      background-position: center -100px;
      overflow: hidden; } }
  .ctn_about .into {
    display: flex;
    column-gap: 40px; }
    @media screen and (max-width: 1024px) {
      .ctn_about .into {
        flex-direction: column; } }
  .ctn_about .column {
    position: relative;
    width: 30%;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) {
      .ctn_about .column {
        width: auto; } }
  .ctn_about .lay {
    z-index: 2; }
  @media screen and (max-width: 1024px) {
    .ctn_about .item_1 {
      height: 500px; }
      .ctn_about .item_1 .num {
        font-size: 14em;
        text-align: center; }
      .ctn_about .item_1 .title {
        font-size: 30px;
        line-height: 0.9;
        bottom: auto; } }
  @media screen and (max-width: 1024px) {
    .ctn_about .item_2 {
      order: 2;
      margin-top: 50px; } }
  .ctn_about .deco {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 400px;
    transform: skew(-24deg);
    z-index: 2;
    background: #fff;
    left: 24.5%; }
    @media screen and (max-width: 1024px) {
      .ctn_about .deco {
        left: 23%; } }
  .ctn_about .dec {
    position: absolute;
    transform: skew(-24deg);
    background: #E51C2F;
    right: 25%;
    width: 4px;
    height: 50px;
    top: 0; }
    @media screen and (max-width: 1024px) {
      .ctn_about .dec {
        display: none; } }
  .ctn_about .into {
    display: flex;
    column-gap: 40px;
    position: relative; }
    .ctn_about .into .p_min {
      padding-right: 50px; }
      @media screen and (max-width: 1024px) {
        .ctn_about .into .p_min {
          padding: 0; } }
  .ctn_about .number_1 {
    position: absolute;
    right: 5%;
    font-size: 326px;
    bottom: -90px;
    color: #E9EAEC; }
    @media screen and (max-width: 1024px) {
      .ctn_about .number_1 {
        font-size: 12em;
        line-height: 1;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
        bottom: -190px;
        z-index: 2; } }
  .ctn_about.uniq {
    padding: 50px 0;
    margin-top: -200px; }
    @media screen and (max-width: 1024px) {
      .ctn_about.uniq {
        margin-top: -170px;
        overflow-y: visible;
        padding-top: 500px; }
        .ctn_about.uniq .deco {
          left: 35%; } }
    @media screen and (max-width: 1024px) {
      .ctn_about.uniq .into {
        padding: 0; }
        .ctn_about.uniq .into .column {
          top: -220px; }
          .ctn_about.uniq .into .column .num {
            font-size: 223px;
            text-align: center; }
          .ctn_about.uniq .into .column .title {
            font-size: 30px;
            line-height: 28px;
            bottom: -200px;
            right: 30px; } }
    .ctn_about.uniq .right {
      position: relative;
      z-index: 2;
      width: 66.66666%;
      padding-left: 40px;
      box-sizing: border-box; }
      @media screen and (max-width: 1024px) {
        .ctn_about.uniq .right {
          width: 100%;
          padding: 0; } }
      .ctn_about.uniq .right .numbers {
        margin-top: 120px;
        padding: 0 3%; }
        @media screen and (max-width: 414px) {
          .ctn_about.uniq .right .numbers {
            margin-top: 30px; }
            .ctn_about.uniq .right .numbers .item {
              width: 50%;
              padding: 20px 0;
              padding-right: 20px;
              box-sizing: border-box; } }
      .ctn_about.uniq .right .qu {
        background: #E51C2F;
        color: #fff;
        padding: 45px;
        max-width: 515px;
        font-family: "Bebas";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 24px;
        transform: skew(-24deg) translate(90px, 0); }
        @media screen and (max-width: 1024px) {
          .ctn_about.uniq .right .qu {
            transform: none; } }
        .ctn_about.uniq .right .qu p {
          padding: 0 40px;
          margin: 0;
          transform: skew(24deg); }
          @media screen and (max-width: 1024px) {
            .ctn_about.uniq .right .qu p {
              transform: none;
              padding: 0; } }

.numbers {
  display: flex;
  flex-wrap: wrap; }
  .numbers .item {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    width: 33.3333%;
    box-sizing: border-box;
    padding: 16px 0;
    padding-right: 40px; }
    .numbers .item .title {
      margin: 0;
      padding: 0; }

.ctn_home {
  height: 100%;
  display: flex;
  padding-left: 6%;
  align-items: stretch;
  overflow: hidden; }
  @media screen and (max-width: 1024px) {
    .ctn_home {
      display: block;
      padding: 30px 3% 0; } }
  .ctn_home.dop {
    height: 460px; }
    @media screen and (max-width: 1024px) {
      .ctn_home.dop {
        height: auto; } }
    @media screen and (max-width: 1024px) {
      .ctn_home.dop .home_links {
        padding: 0 0 30px 0; } }
  .ctn_home .home_links {
    display: flex;
    align-items: stretch;
    width: 100%; }
    @media screen and (max-width: 1024px) {
      .ctn_home .home_links {
        display: block;
        padding: 30px 0; } }
  .ctn_home .left {
    width: 50%;
    display: flex;
    align-items: center; }
    .ctn_home .left h1 {
      margin: 0;
      padding: 0; }
  .ctn_home .item {
    display: flex;
    align-items: flex-end;
    width: 25%;
    background: black;
    color: #fff;
    transform: skew(-24deg);
    text-decoration: none;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .ctn_home .item {
        width: 100%;
        transform: skew(0);
        overflow: hidden; } }
    .ctn_home .item .back {
      position: absolute;
      transform: skew(24deg);
      left: -70%;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-position: left center;
      width: 300%;
      opacity: 0.8;
      transition: all 0.2s ease-in-out; }
      @media screen and (max-width: 1024px) {
        .ctn_home .item .back {
          transform: skew(0);
          left: 0;
          width: 100%; } }
    .ctn_home .item:nth-child(1) .back {
      background-image: url(../img/01.jpg); }
    .ctn_home .item:nth-child(2) .back {
      background-image: url(../img/02.jpg); }
    .ctn_home .item:nth-child(3) .back {
      background-image: url(../img/03.jpg); }
    .ctn_home .item:nth-child(4) .back {
      background-image: url(../img/04.jpg); }
    @media screen and (min-width: 414px) {
      .ctn_home .item:hover {
        width: 30%; }
        .ctn_home .item:hover .back {
          opacity: 1; } }
    .ctn_home .item .into {
      transform: skew(24deg) translate(-4px);
      padding: 0px 0px;
      line-height: 14px !important;
      height: 210px;
      display: flex;
      flex-direction: column;
      width: 200px; }
      @media screen and (max-width: 1024px) {
        .ctn_home .item .into {
          transform: skew(-24deg) translate(10px);
          overflow: hidden;
          width: 100%;
          padding: 10px 0;
          height: auto; } }
      .ctn_home .item .into h2 {
        font-size: 24px;
        line-height: 30px; }
        @media screen and (max-width: 1024px) {
          .ctn_home .item .into h2 {
            transform: skew(24deg) translateX(30px);
            margin: 0; } }
        @media screen and (max-width: 1184px) {
          .ctn_home .item .into h2 {
            font-size: 22px; } }
      .ctn_home .item .into .num {
        font-size: 220px;
        line-height: 120px;
        font-family: "Bebas";
        transform: translate(-8px);
        font-weight: 500;
        color: #fff; }
        @media screen and (max-width: 1024px) {
          .ctn_home .item .into .num {
            transform: skew(24deg) translateX(-16px); } }
    .ctn_home .item .description {
      font-size: 10px;
      text-transform: uppercase; }
      @media screen and (max-width: 1024px) {
        .ctn_home .item .description {
          transform: skew(24deg) translateX(48px); } }

.contact_grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  padding-bottom: 80px;
  padding-top: 30px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .contact_grid {
      display: block;
      padding-top: 0; } }
  .contact_grid .item {
    width: 31%;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(97, 117, 132, 0.08);
    box-sizing: border-box;
    line-height: 22px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
    position: relative; }
    @media screen and (max-width: 768px) {
      .contact_grid .item {
        width: auto; } }
    .contact_grid .item header {
      font-family: "Bebas";
      font-weight: bold;
      font-size: 20px;
      line-height: 1;
      padding: 0 0 16px 0;
      border-bottom: 1px solid #E9EAEC;
      margin-bottom: 20px; }
    .contact_grid .item .tel {
      font-family: "Bebas";
      font-weight: bold;
      font-size: 28px; }
    .contact_grid .item a {
      text-decoration: none;
      font-size: 14px; }
    .contact_grid .item .ctn div {
      margin: 8px 0 0px 0; }
  .contact_grid .bg_rad {
    transform: scale(2.7);
    top: 18%;
    right: -5%; }
    @media screen and (max-width: 768px) {
      .contact_grid .bg_rad {
        top: 7%; } }
    .contact_grid .bg_rad:nth-child(1) {
      left: 8%;
      right: auto;
      bottom: 0%;
      top: auto;
      transform: scale(3.2); }

.date {
  font-size: 10px;
  text-transform: uppercase;
  background: #E9EAEC;
  padding: 0 4px;
  color: #9BA1A7;
  display: inline-block;
  margin-top: 20px;
  line-height: 1.6; }
  .date + h1 {
    padding: 24px 0 0 0; }
    @media screen and (max-width: 1024px) {
      .date + h1 {
        padding-top: 10px; } }

.ctn_coaching {
  padding: 80px 0 120px 0; }
  @media screen and (max-width: 1024px) {
    .ctn_coaching {
      padding: 40px 0; } }
  .ctn_coaching .into {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .ctn_coaching .into {
        display: block; } }
  .ctn_coaching ul {
    max-width: 620px;
    margin-top: 68px; }
    .ctn_coaching ul p {
      margin-top: 8px; }

.ctn_history {
  padding: 90px 0; }
  .ctn_history .into {
    display: flex;
    justify-content: space-between;
    column-gap: 40px; }
    .ctn_history .into .ctn {
      width: 66.3333%;
      border: 1px solid red; }
    .ctn_history .into .col_3 {
      flex: auto 1; }
  .ctn_history.hero .block {
    position: relative; }

.hero.hist {
  display: block;
  height: auto;
  padding: 90px 0 30px; }
  @media screen and (max-width: 1024px) {
    .hero.hist {
      padding: 30px 0; } }
  .hero.hist .into {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .hero.hist .into {
        display: block; } }
  .hero.hist .block {
    position: relative;
    top: auto;
    width: 55%;
    overflow: visible; }
    @media screen and (max-width: 1024px) {
      .hero.hist .block {
        width: auto;
        height: 340px; } }
    .hero.hist .block .num {
      right: 0; }
      @media screen and (max-width: 1024px) {
        .hero.hist .block .num {
          left: 140px;
          z-index: 2;
          bottom: 10px;
          top: auto; } }
    .hero.hist .block .back {
      background: #40454B;
      width: 500px;
      height: 500px; }
      @media screen and (max-width: 1024px) {
        .hero.hist .block .back {
          width: 140px;
          height: 300px;
          left: 20%; } }
      .hero.hist .block .back:before {
        background: #E51C2F;
        top: 0;
        width: 30px; }
        @media screen and (max-width: 1024px) {
          .hero.hist .block .back:before {
            display: block; } }
    @media screen and (max-width: 1024px) {
      .hero.hist .block .front {
        right: auto;
        left: 55%; } }
    .hero.hist .block img {
      right: 20%; }
      @media screen and (max-width: 1024px) {
        .hero.hist .block img {
          width: 300px; } }

.ctn_news_wrapper {
  padding-left: 12%;
  padding-bottom: 80px; }
  @media screen and (max-width: 1024px) {
    .ctn_news_wrapper {
      padding: 0;
      width: auto; }
      .ctn_news_wrapper .ctn_scroll {
        overflow-x: scroll; } }
  .ctn_news_wrapper .ctn_news {
    padding-left: 12%;
    justify-content: space-between;
    padding-bottom: 20px; }
    @media screen and (max-width: 1024px) {
      .ctn_news_wrapper .ctn_news {
        padding-left: 3%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap; }
        .ctn_news_wrapper .ctn_news .item {
          width: 300px; } }
  .ctn_news_wrapper .btn_wrapper {
    padding-left: 12%; }
    @media screen and (max-width: 1024px) {
      .ctn_news_wrapper .btn_wrapper {
        padding-left: 3%; } }

.ctn_news {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 3%;
  padding-bottom: 120px;
  padding-top: 40px;
  justify-content: flex-start; }
  @media screen and (max-width: 1024px) {
    .ctn_news {
      display: block;
      padding-top: 10px; } }
  .ctn_news .item {
    background: #FFF;
    box-shadow: 0px 10px 22px 0px rgba(97, 117, 132, 0.1);
    box-sizing: border-box;
    text-decoration: none;
    color: #40454B;
    margin: 20px 0;
    flex: 1 1 1;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 22.6%;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .ctn_news .item {
        width: auto; } }
    .ctn_news .item:after {
      content: '';
      background: #E9EAEC;
      width: 10px;
      height: 20px;
      transform: skew(-24deg);
      position: absolute;
      bottom: 0;
      right: 15%; }
    .ctn_news .item:before {
      content: '';
      background: #E9EAEC;
      width: 2px;
      height: 40px;
      transform: skew(-24deg);
      position: absolute;
      bottom: 0;
      right: 19%; }
    .ctn_news .item img {
      display: block;
      width: 100%;
      filter: grayscale(100%);
      transition: all 0.2s ease-in-out; }
    .ctn_news .item h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0 0 16px 0; }
    .ctn_news .item .ctn {
      padding: 24px;
      padding-bottom: 20px;
      display: block;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
      .ctn_news .item .ctn span {
        display: block;
        line-height: 1;
        padding: 0; }
    .ctn_news .item:hover {
      transform: scale(1.04);
      box-shadow: 0px 20px 40px 0px rgba(97, 117, 132, 0.2);
      z-index: 2; }
      .ctn_news .item:hover img {
        filter: grayscale(0%); }
  .ctn_news .date {
    margin: 0; }

.bg_rad {
  background: url(../img/bg_rad.png) center;
  background-size: 100%;
  position: absolute;
  width: 400px;
  height: 400px; }

.hero_head {
  margin-top: 20px;
  z-index: 2;
  position: relative; }

.hero {
  display: flex;
  justify-content: flex-end;
  height: 200px; }
  @media screen and (max-width: 1024px) {
    .hero {
      overflow-x: hidden;
      height: 390px; } }
  .hero .bg {
    margin: -150px auto 0;
    transform: scale(2); }
    @media screen and (max-width: 1024px) {
      .hero .bg {
        transform: scale(1) translate(-100px, 20px);
        margin: auto;
        width: 100%; } }
  .hero .block {
    overflow: hidden;
    position: absolute;
    top: 80px;
    width: 100%;
    height: 620px; }
    @media screen and (max-width: 1024px) {
      .hero .block {
        top: auto;
        height: inherit; } }
    .hero .block .num {
      font-family: "Bebas";
      font-weight: 500;
      font-size: 240px;
      position: absolute;
      right: 8%;
      z-index: 2;
      bottom: 100px;
      color: #E9EAEC;
      line-height: 1; }
      @media screen and (max-width: 1024px) {
        .hero .block .num {
          right: auto;
          font-size: 138px;
          top: 0;
          left: 2%;
          z-index: 1; } }
      .hero .block .num:after {
        content: '';
        background: #E9EAEC;
        transform: skew(-24deg);
        position: absolute;
        height: 78px;
        width: 2px;
        bottom: -10px;
        right: -10px; }
        @media screen and (max-width: 1024px) {
          .hero .block .num:after {
            display: none; } }
    .hero .block img {
      position: absolute;
      top: 20px;
      right: 5%;
      z-index: 2; }
      @media screen and (max-width: 1024px) {
        .hero .block img {
          top: auto;
          left: -10px;
          width: 140%; } }
    .hero .block .back {
      background: #9BA1A7;
      width: 600px;
      transform: skew(-24deg);
      right: 0;
      top: 0;
      bottom: 30px;
      position: absolute; }
      @media screen and (max-width: 1024px) {
        .hero .block .back {
          width: 220px; } }
      .hero .block .back:before {
        content: '';
        position: absolute;
        left: -50px;
        background: #E9EAEC;
        top: 40px;
        width: 12px;
        height: 142px; }
        @media screen and (max-width: 1024px) {
          .hero .block .back:before {
            display: none; } }
    .hero .block .front {
      right: -25px;
      background: #fff;
      width: 280px;
      top: 0;
      bottom: 0;
      position: absolute;
      transform: skew(-24deg);
      z-index: 2; }
      @media screen and (max-width: 1024px) {
        .hero .block .front {
          width: 140px;
          right: -35%; } }
    .hero .block .dec {
      bottom: 0;
      right: 380px;
      position: absolute;
      background: #E51C2F;
      width: 34px;
      height: 100px;
      transform: skew(-24deg);
      z-index: 3; }
      @media screen and (max-width: 1024px) {
        .hero .block .dec {
          right: 40px;
          height: 80px;
          bottom: -20px; } }

#menu {
  width: 400px; }
  @media screen and (max-width: 1024px) {
    #menu {
      width: 100%; } }
  #menu .modal_content_wrapper {
    margin: 0; }
    #menu .modal_content_wrapper .modal_content {
      background: #E51C2F;
      animation: menu_ani 0.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
      display: flex; }
      #menu .modal_content_wrapper .modal_content .into {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px; }
    #menu .modal_content_wrapper .cross_close {
      width: 65px;
      height: 65px; }
  #menu nav {
    counter-reset: section; }
    #menu nav a {
      display: block;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      padding: 6px 0; }
      #menu nav a:before {
        color: #F94254;
        font-size: 12px;
        margin-right: 10px;
        content: "0" counter(section);
        counter-increment: section;
        transition: all 0.2s ease-in-out; }
      #menu nav a:hover:before {
        margin-right: 20px; }
  #menu .cross_close:before, #menu .cross_close:after {
    background: #fff; }
  #menu .lang_selector {
    display: flex;
    margin: 20px 0 0 0;
    column-gap: 10px; }
    #menu .lang_selector a, #menu .lang_selector span {
      display: flex;
      border: 1px solid #F94254;
      align-items: center;
      height: 44px;
      width: 46px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      justify-content: center; }
    #menu .lang_selector span {
      opacity: 0.6; }

@keyframes menu_ani {
  0% {
    transform: translateX(-100px) scale(1);
    opacity: 0; } }

.systems {
  display: flex;
  justify-content: flex-start;
  column-gap: 2%;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 80px; }
  @media screen and (max-width: 1024px) {
    .systems {
      flex-direction: column;
      padding-top: 0px; } }
  .systems .item {
    border: 1px solid #E9EAEC;
    text-align: center;
    position: relative;
    padding: 0px;
    width: 23.5%;
    box-sizing: border-box;
    margin: 16px 0; }
    @media screen and (max-width: 1024px) {
      .systems .item {
        width: auto;
        margin: 8px 0;
        padding: 0; } }
    .systems .item a.system_img_link {
      display: block;
      transition: all 0.2s ease-in-out;
      padding-bottom: 120px;
      z-index: 2;
      position: relative; }
      @media screen and (max-width: 1024px) {
        .systems .item a.system_img_link {
          align-items: center;
          text-decoration: none;
          width: 100%;
          padding: 0; } }
    .systems .item svg {
      fill: #000;
      width: 130px;
      height: 130px;
      margin: 40px auto 0;
      display: block;
      transition: all 0.2s ease-in-out;
      transform: scale(1.1); }
      @media screen and (max-width: 1024px) {
        .systems .item svg {
          margin: 0;
          transform: scale(1); } }
    .systems .item .title {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
      padding: 0 24px;
      max-width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      color: #1B1B1B;
      transition: all 0.2s ease-in-out;
      line-height: 1;
      box-sizing: border-box;
      text-decoration: none; }
      .systems .item .title .button_link {
        font-size: 10px;
        font-weight: bold;
        display: block;
        margin: auto;
        font-family: 'Pragmatica', sans-serif;
        z-index: 2; }
      @media screen and (max-width: 1024px) {
        .systems .item .title {
          text-align: left;
          left: 35%;
          right: auto;
          width: 65%; }
          .systems .item .title .button_link {
            text-align: left;
            margin: 0;
            padding-top: 8px; } }
    .systems .item:hover svg {
      fill: #E51C2F; }
    .systems .item:hover .title {
      color: #E51C2F; }

.ctn_systems {
  background: #E51C2F;
  color: #fff;
  padding: 95px 0;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .ctn_systems {
      padding: 40px 0; } }
  .ctn_systems .item {
    border-color: #F94254;
    transition: all 0.2s ease-in-out; }
    .ctn_systems .item svg {
      fill: #fff; }
    .ctn_systems .item .title {
      color: #fff; }
    .ctn_systems .item .button_link {
      color: #fff; }
      .ctn_systems .item .button_link:after {
        background: #fff; }
    .ctn_systems .item:hover svg {
      fill: #fff; }
    .ctn_systems .item:hover .title {
      color: #fff; }
  .ctn_systems.red .title {
    padding-top: 0px; }
    .ctn_systems.red .title:after {
      background-color: #fff; }
  .ctn_systems.red .item:hover {
    transform: scale(1.04);
    box-shadow: 0px 20px 40px 0px rgba(140, 7, 20, 0.2);
    z-index: 2;
    border-color: #fff; }
  .ctn_systems.two {
    display: flex;
    column-gap: 40px;
    padding: 95px 6%; }
    @media screen and (max-width: 768px) {
      .ctn_systems.two {
        display: block;
        padding: 40px 3%; } }
    .ctn_systems.two .into {
      padding: 0; }
      .ctn_systems.two .into:first-child {
        order: 2;
        width: 33.3333%; }
      .ctn_systems.two .into.systems {
        width: 66.6666%;
        column-gap: 4%; }
        @media screen and (max-width: 768px) {
          .ctn_systems.two .into.systems {
            width: auto; } }
        .ctn_systems.two .into.systems .item {
          width: 30.6666%; }
          @media screen and (max-width: 1024px) {
            .ctn_systems.two .into.systems .item {
              width: auto; }
              .ctn_systems.two .into.systems .item .title {
                text-align: left;
                left: 35%;
                right: auto;
                width: 65%;
                padding: 0 24px;
                margin: 0; } }
  .ctn_systems .dec {
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    width: 30px;
    height: 50px;
    bottom: 0;
    right: 15%;
    transform: skew(-24deg); }
    .ctn_systems .dec:before {
      content: '';
      background: #fff;
      width: 3px;
      height: 140px;
      color: white;
      bottom: 0;
      position: absolute;
      left: -15px; }

.tdec:after {
  content: '';
  transform: skew(-24deg) translate(30px, 6px);
  display: inline-block;
  height: 78px;
  background: #E51C2F;
  width: 2px;
  position: absolute; }
  @media screen and (max-width: 1024px) {
    .tdec:after {
      height: 50px;
      transform: skew(-24deg) translate(30px, 4px); } }

.num_ctn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 0;
  left: 0;
  right: 0; }
  .num_ctn .title {
    line-height: 48px;
    font-size: 50px;
    color: #E51C2F;
    position: absolute;
    bottom: -270px;
    right: 20px;
    z-index: 2; }

.num {
  font-size: 375px;
  font-family: "Bebas";
  font-weight: bold;
  line-height: 0.5;
  color: #E51C2F;
  z-index: 1; }

.cou {
  padding: 0 40px; }
  @media screen and (max-width: 414px) {
    .cou {
      padding: 0; } }
  .cou .item {
    display: inline-flex !important;
    align-items: center;
    height: 100px;
    margin: 0;
    box-sizing: border-box; }
    @media screen and (max-width: 414px) {
      .cou .item {
        height: 60px; } }
    .cou .item img {
      display: block;
      margin: 0 30px; }
      @media screen and (max-width: 414px) {
        .cou .item img {
          margin: 0 10px; } }
  .cou .slick-prev {
    left: 0; }
    .cou .slick-prev:before {
      content: '';
      background: url(../img/arrow_2.svg) no-repeat center;
      top: 0;
      bottom: 0;
      margin: auto;
      left: -2px;
      position: absolute;
      right: 0;
      transform: rotate(-180deg); }
  .cou .slick-next {
    right: 0; }
    .cou .slick-next:before {
      content: '';
      background: url(../img/arrow_2.svg) no-repeat center;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 2px;
      position: absolute;
      right: 0; }
  .cou .slick-arrow {
    border: 2px solid #F94254;
    width: 44px;
    height: 44px;
    background: #E51C2F;
    z-index: 2; }
    @media screen and (max-width: 414px) {
      .cou .slick-arrow {
        display: none !important; } }

.cat_menu {
  margin: 0; }
  .cat_menu li {
    padding: 0;
    margin: 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 12px; }
    .cat_menu li.active {
      border-left: 1px solid #E51C2F; }
      .cat_menu li.active span {
        color: #E51C2F;
        position: relative; }
        .cat_menu li.active span:before {
          content: '';
          width: 3px;
          background: #E51C2F;
          left: 0;
          top: 0;
          bottom: 0;
          position: absolute; }
    .cat_menu li:before {
      display: none; }
    .cat_menu li a, .cat_menu li span {
      color: #40454B;
      text-decoration: none;
      display: block;
      padding: 14px 24px; }
    .cat_menu li ul {
      padding: 10px 0; }
    .cat_menu li li {
      font-size: 14px;
      text-transform: none;
      font-weight: normal; }
      .cat_menu li li.active {
        border: none; }
      .cat_menu li li a, .cat_menu li li span {
        padding: 6px 0;
        padding-left: 36px;
        background: url(../img/arrow-g.svg) 24px center no-repeat;
        line-height: 16px; }
        .cat_menu li li a:before, .cat_menu li li span:before {
          display: none; }
      .cat_menu li li span {
        background-image: url(../img/arrow-r.svg); }

.waside {
  display: flex;
  column-gap: 40px;
  margin: 36px 0 100px;
  justify-content: space-between; }
  @media screen and (max-width: 1024px) {
    .waside {
      flex-direction: column;
      margin: 20px 0 40px; } }
  .waside h1 {
    padding-top: 0; }
  .waside .ctn {
    border: 1px solid #E9EAEC;
    padding: 40px 5% 40px;
    width: 75%;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) {
      .waside .ctn {
        padding: 0;
        border: none;
        width: auto; } }
  .waside.search_result .ctn {
    border: none;
    padding: 0; }
  .waside aside {
    width: 25%; }
    @media screen and (max-width: 1024px) {
      .waside aside {
        width: auto;
        border: 1px solid #E9EAEC;
        margin-top: 10px; } }

.specs {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center; }
  .specs td {
    padding: 16px 24px; }
    .specs td span {
      white-space: nowrap; }
  .specs thead {
    font-size: 12px;
    line-height: 14px; }
  .specs.oem {
    max-width: 500px; }
  .specs.links tr:hover {
    background: #E9EAEC;
    cursor: pointer; }

.product_top {
  display: flex;
  column-gap: 40px;
  padding-bottom: 20px;
  padding-top: 24px; }
  @media screen and (max-width: 1024px) {
    .product_top {
      flex-direction: column;
      overflow: hidden; } }
  .product_top h1 {
    margin: 0;
    padding: 0; }

.p_img_ctn, .p_desc {
  width: 30%; }
  @media screen and (max-width: 1024px) {
    .p_img_ctn, .p_desc {
      width: auto; } }

.p_desc {
  width: 70%; }

.p_img_ctn {
  padding-top: 5px; }
  @media screen and (max-width: 1024px) {
    .p_img_ctn {
      margin-bottom: 20px; } }
  .p_img_ctn .p_img_slider .item img {
    display: block;
    width: 100%; }
  .p_img_ctn .p_img_slider .slick-dots li {
    margin: 0; }
    .p_img_ctn .p_img_slider .slick-dots li.slick-active button:before {
      color: #E51C2F; }

.de_flex_ctn {
  padding: 10px 0;
  margin: 10px 0;
  max-width: 860px; }

.de_flex {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #DADBEE; }

.art {
  margin: 0px 0 10px 0; }

.market_links {
  display: flex;
  column-gap: 20px; }
  .market_links a {
    display: block;
    overflow: hidden;
    width: 40px;
    height: 40px; }
    .market_links a img {
      display: block;
      width: 100%;
      filter: grayscale(100%);
      transition: all 0.2s ease-in-out; }
    .market_links a:hover img {
      filter: grayscale(0%); }

.product_bot {
  padding-bottom: 80px; }
  .product_bot .market_links a {
    width: 60px;
    height: 60px; }

.title_d {
  height: 55px;
  width: 2px;
  background: #E51C2F;
  display: inline-block;
  transform: skew(-24deg) translate(4px, 2px);
  margin: 0 14px; }
  @media screen and (max-width: 1024px) {
    .title_d {
      height: 32px; } }

.search_modal {
  background: #fff;
  border-top: 1px solid #E9EAEC;
  padding: 16px 0;
  top: 84px;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 4;
  overflow-x: scroll; }
  @media screen and (max-width: 1024px) {
    .search_modal {
      top: 60px; } }
  .search_modal .into {
    max-width: 900px;
    width: 100%;
    margin: 0 auto; }
  .search_modal .search_links {
    padding: 10px 0; }
    .search_modal .search_links a {
      display: block; }

.search_tags {
  padding-bottom: 10px; }
  .search_tags .item {
    display: inline-flex;
    background: #E51C2F;
    color: #fff;
    padding: 3px 8px;
    padding-right: 3px;
    border-radius: 100px;
    align-items: center;
    line-height: 12px;
    margin: 0 5px 0 0; }
  .search_tags .close {
    background: #E9EAEC;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin-left: 6px;
    position: relative;
    cursor: pointer; }
    .search_tags .close:after, .search_tags .close:before {
      width: 1px;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 10px;
      background: #40454B;
      position: absolute;
      margin: auto;
      transform: rotate(45deg); }
    .search_tags .close:before {
      transform: rotate(-45deg); }
    .search_tags .close:hover {
      background: #E51C2F; }
      .search_tags .close:hover:after, .search_tags .close:hover:before {
        background: #fff; }

.marks {
  padding: 10px 0; }
  .marks ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-count: 3;
    column-gap: 20px; }
    @media screen and (max-width: 1024px) {
      .marks ul {
        column-count: 2; } }
    .marks ul li {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      position: relative;
      padding: 0px 0 0 24px; }
      .marks ul li.clear {
        height: 16px; }
      .marks ul li span {
        border-left: 1px solid #E51C2F;
        display: block;
        padding: 3px 10px;
        cursor: pointer; }
        .marks ul li span:hover {
          color: #E51C2F; }
      .marks ul li:before {
        content: attr(data-rel);
        transform: skew(0);
        background: none;
        position: absolute;
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        margin-top: 5px;
        color: #E51C2F; }
      .marks ul li.off:before {
        content: ''; }

.filter {
  display: flex;
  height: 100%;
  align-items: center;
  column-gap: 16px;
  padding: 0 40px;
  position: relative; }
  .filter:before {
    content: '';
    left: 0;
    width: 1px;
    height: 100%;
    position: absolute;
    background: #E9EAEC;
    transform: skew(-24deg); }
  .filter .checkbox_wrapper {
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    color: #40454B;
    font-weight: normal;
    line-height: 16px; }
    .filter .checkbox_wrapper span {
      width: 16px;
      height: 16px; }
      .filter .checkbox_wrapper span:after {
        width: 16px;
        height: 16px; }

.map_bar {
  width: 20%;
  background: #E9EAEC;
  overflow-x: scroll;
  position: fixed;
  bottom: 0;
  top: 84px; }
  @media screen and (max-width: 414px) {
    .map_bar {
      width: 100%;
      top: 93px;
      bottom: auto; } }
  .map_bar nav {
    padding-top: 48px; }
    @media screen and (max-width: 414px) {
      .map_bar nav {
        display: none; } }
    .map_bar nav .item {
      font-size: 14px;
      line-height: 1.3;
      padding: 16px;
      margin: 0 0 1px 0;
      background: #fff;
      cursor: pointer;
      transition: all 0.2s ease-in-out; }
      .map_bar nav .item:hover {
        background: #E9EAEC; }
      .map_bar nav .item.active {
        background: #E51C2F;
        color: #fff; }
        .map_bar nav .item.active .name:before {
          background: #fff; }
      .map_bar nav .item b {
        margin: 0 0 4px 0;
        display: block; }
      .map_bar nav .item .address {
        font-size: 12px;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 2px 0 0 0; }
  .map_bar .market_links {
    position: fixed;
    padding: 4px 0;
    justify-content: center;
    margin: auto;
    width: 20%;
    background: #E9EAEC;
    z-index: 2; }
    @media screen and (max-width: 414px) {
      .map_bar .market_links {
        width: 100%; } }

.name:before {
  content: '';
  width: 6px;
  height: 14px;
  background: #E51C2F;
  display: inline-block;
  transform: skew(-24deg);
  margin: 0 8px -1px 0; }

.prompt {
  left: 20%;
  position: fixed;
  width: 16%;
  padding: 24px 16px;
  top: 84px;
  bottom: 0;
  overflow-x: scroll;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border-left: 1px solid #E9EAEC;
  box-sizing: border-box;
  z-index: 2; }
  @media screen and (max-width: 414px) {
    .prompt {
      left: 1px;
      width: auto;
      right: 1px;
      top: 142px;
      border: none; } }
  .prompt .phones {
    font-size: 12px;
    margin: 16px 0; }
    .prompt .phones a {
      display: block;
      padding: 4px 0;
      font-weight: bold; }
  .prompt .address {
    font-size: 12px;
    line-height: 15px;
    background: url(../img/flag.svg) left 2px no-repeat;
    padding-left: 16px;
    margin: 16px 0; }
  .prompt .contact {
    font-size: 12px;
    line-height: 16px; }
    .prompt .contact a {
      display: block;
      margin: 4px 0; }
  .prompt .gallery {
    margin: 24px 0; }
    .prompt .gallery a {
      display: block;
      margin: 2px 0;
      padding: 0; }
      .prompt .gallery a img {
        width: 100%;
        display: block; }
  .prompt .close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer; }
    .prompt .close:after, .prompt .close:before {
      content: '';
      width: 1px;
      height: 14px;
      background: #40454B;
      transform: rotate(45deg); }
    .prompt .close:after {
      transform: rotate(-45deg); }
    .prompt .close:hover {
      transform: scale(1.1); }

.map {
  background: #40454B; }

.map_frame {
  position: fixed;
  top: 84px;
  bottom: 0;
  left: 20%;
  right: 0; }
  @media screen and (max-width: 414px) {
    .map_frame {
      left: 0;
      top: 141px; } }

.point {
  position: absolute;
  background: rgba(229, 28, 47, 0.4);
  padding: 10px;
  cursor: pointer; }
  .point b {
    display: block;
    width: 14px;
    height: 14px;
    color: #fff;
    background: #E51C2F;
    padding: 0px; }
    .point b:after, .point b:before {
      content: '';
      width: 1px;
      height: 7px;
      background: #fff; }
    .point b:before {
      transform: rotate(-90deg); }
  .point.active {
    padding: 4px; }
    .point.active b {
      padding: 6px; }

.ctn_timeline {
  padding: 40px 0 180px; }
  @media screen and (max-width: 768px) {
    .ctn_timeline {
      padding: 40px 0; } }
  .ctn_timeline .timeline {
    display: flex;
    font-family: "Bebas";
    font-weight: bold;
    margin-top: 80px;
    counter-reset: section; }
    @media screen and (max-width: 414px) {
      .ctn_timeline .timeline {
        flex-direction: column;
        margin-top: 40px;
        position: relative; }
        .ctn_timeline .timeline:after {
          content: '';
          position: absolute;
          width: 1px;
          top: 20px;
          bottom: 24px;
          left: 53px;
          background: #E51C2F; } }
    .ctn_timeline .timeline .item {
      width: 16.6666%;
      line-height: 1;
      border-bottom: 1px solid #E51C2F;
      padding: 0 40px 20px 0;
      display: flex;
      align-items: flex-end;
      position: relative;
      box-sizing: border-box;
      font-size: 18px; }
      @media screen and (max-width: 414px) {
        .ctn_timeline .timeline .item {
          width: 100%;
          border-bottom: none;
          padding: 16px 0;
          padding-left: 80px; } }
      .ctn_timeline .timeline .item:last-child {
        border-bottom: 0; }
      .ctn_timeline .timeline .item:before {
        content: '';
        position: absolute;
        left: -3px;
        bottom: -4px;
        background: #E51C2F;
        border-radius: 100%;
        width: 7px;
        height: 7px; }
        @media screen and (max-width: 414px) {
          .ctn_timeline .timeline .item:before {
            left: 50px;
            top: -4px;
            bottom: 0;
            margin: auto; } }
      .ctn_timeline .timeline .item:after {
        content: "0" counter(section);
        counter-increment: section;
        position: absolute;
        bottom: -54px;
        font-size: 30px;
        line-height: 1;
        left: -2px; }
        @media screen and (max-width: 414px) {
          .ctn_timeline .timeline .item:after {
            bottom: 0;
            left: 0;
            top: 0;
            margin: auto;
            line-height: 1.7; } }

.ctn_testing {
  background: #E51C2F;
  color: #fff;
  padding: 90px 0 0;
  position: relative; }
  @media screen and (max-width: 414px) {
    .ctn_testing {
      padding: 40px 0; } }
  .ctn_testing .into {
    display: flex;
    column-gap: 40px; }
    @media screen and (max-width: 414px) {
      .ctn_testing .into {
        flex-direction: column;
        padding-bottom: 190px; } }
    .ctn_testing .into .left {
      width: 33.333%;
      position: relative; }
      @media screen and (max-width: 414px) {
        .ctn_testing .into .left {
          width: auto;
          position: unset; } }
  .ctn_testing .tdec:after {
    background: #fff; }
  .ctn_testing ul {
    font-family: "Bebas";
    font-weight: bold;
    line-height: 26px;
    font-size: 24px;
    margin-top: 36px;
    margin-bottom: 90px; }
    .ctn_testing ul li:before {
      background: #fff; }
  .ctn_testing .dec {
    height: 310px;
    width: 200px;
    transform: skew(-24deg);
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 70px; }
    @media screen and (max-width: 414px) {
      .ctn_testing .dec {
        left: 80px; } }
    .ctn_testing .dec img {
      height: 100%;
      left: -34%;
      display: block;
      transform: skew(24deg);
      position: absolute; }
    .ctn_testing .dec:after {
      background: #fff;
      width: 3px;
      height: 200px;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0; }
  .ctn_testing .dec_2 {
    background: rgba(255, 255, 255, 0.4);
    transform: skew(-24deg);
    bottom: 0;
    height: 60px;
    width: 30px;
    position: absolute;
    right: 40%; }
    @media screen and (max-width: 414px) {
      .ctn_testing .dec_2 {
        right: 32%; } }

table .options input {
  padding: 0 10px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box; }
