@font-face {
    font-family: 'Bebas';
    src: url('../fonts/bebasneue_light-webfont.eot');
    src: url('../fonts/bebasneue_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_light-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_light-webfont.woff') format('woff'),
         url('../fonts/bebasneue_light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas';
    src: url('../fonts/subset-BebasNeueRegular.eot');
    src: url('../fonts/subset-BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/subset-BebasNeueRegular.woff2') format('woff2'),
         url('../fonts/subset-BebasNeueRegular.woff') format('woff'),
         url('../fonts/subset-BebasNeueRegular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas';
    src: url('../fonts/subset-BebasNeueBold.eot');
    src: url('../fonts/subset-BebasNeueBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/subset-BebasNeueBold.woff2') format('woff2'),
         url('../fonts/subset-BebasNeueBold.woff') format('woff'),
         url('../fonts/subset-BebasNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Pragmatica';
    src: url('../fonts/pragmatica-book-webfont.eot');
    src: url('../fonts/pragmatica-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pragmatica-book-webfont.woff2') format('woff2'),
         url('../fonts/pragmatica-book-webfont.woff') format('woff'),
         url('../fonts/pragmatica-book-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Pragmatica';
    src: url('../fonts/pragmatica-bold-webfont.eot');
    src: url('../fonts/pragmatica-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pragmatica-bold-webfont.woff2') format('woff2'),
         url('../fonts/pragmatica-bold-webfont.woff') format('woff'),
         url('../fonts/pragmatica-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}