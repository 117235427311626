.tabs_wrapper{
	display: flex;
	flex-direction: column;
	background: #fff;

	.tabs_header{
		display: flex;
		border-bottom: 1px solid $border_color;
		
		@media screen and (max-width: $tablet_ver){
			overflow-x: scroll;
			overflow-y: hidden;
		}

		.tab_link{
			transition: $transition_1;
			display: flex;
			padding: 10px 0;
			margin: 0 24px 0 0;
			margin-bottom: -1px;
			cursor: pointer;
			color: $black;
			text-transform:uppercase;
			font-size: 12px;
			position: relative;
			line-height: 16px;
			align-items: flex-end;
			// white-space: nowrap;
			&:after{
				content: '';
				background: $prime_color;
				height: 0px;
				left: 0;
				right: 0;
				bottom: 0;
				position: absolute;
			}
			&.active{
				color: $prime_color;
				&:after{
					height: 1px;
					transition: $transition_1;
				}
			}
		}
	}

	.tabs_content_item{
		display: none;
		padding: 20px 0;

		&.active{
			display: block;
		}
	}
}
