body{
	line-height: 26px;
	font-size: 17px;
	color: $font_color;
	font-family: 'Pragmatica', sans-serif;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	@media screen and (max-width: $mobile) {
		font-size: 16px;
	}
}
%header_title{
	font-family: $font_titles;
	font-weight: normal;
	font-size: 70px;
	line-height: 64px;
	max-width: $text_max_width;
	margin: 0;
}
h1, .title{
	@extend %header_title;
	padding-top: 50px;

	@media screen and (max-width: $tablet_hor) {
		font-size: 40px;
		line-height: 36px;
		padding-top: 10px;
		margin-bottom: 24px;
	}

}
h2{
	@extend %header_title;
	font-size: 30px;
	line-height: 30px;
	padding-top: 10px;
	@media screen and (max-width: $tablet_hor) {
		font-size: 24px;
		line-height: 30px;
		padding-top: 20px;
		margin-bottom: 20px;
	}
}
h3{
	@extend %header_title;
	font-size: 24px;
	line-height: 24px;
	font-weight: bold;
	margin: 0;
}
.product_options h1{
	padding-top: 10px;
}
p{
	max-width: $text_max_width;
	margin:24px 0;
}
.p_min{
	font-size: 14px;
	line-height: 22px;
}
b,strong{
	font-weight: bold;
	// font-family: $font_titles;
}
a{
	color: $link_color;
	transition: $transition_1;
	&:hover{
		text-decoration: none;
		color: $black;
	}
}
.danger{
	color: $danger;
}
.success{
	color: $success;
}
.disable{
	color: $disable;
}
.text_min{
	font-size: 16px;
}
blockquote{
	max-width: $text_max_width;
	margin: 90px 0;
	padding: 4px 0 0 24px;
	box-sizing: border-box;
	font-size: 30px;
	line-height: 30px;
	border-left: 2px solid #000;
	font-family: $font_titles;
	font-weight: bold;
}

ul{
	padding: 0;
	list-style: none;
	$list_mark_width:7px;
	max-width: $text_max_width;

	h2{
		margin: 0;
		padding: 0;
		& + p{
			margin-top: 8px;
		}
	}

	li{
		margin: 20px 0;
		padding: 0;
		padding-left: $list_mark_width + 20px;
		position: relative;

		&:before{
			content: '';
			top:1px;
			position: absolute;
			height: 16px;
			background: $prime_color;
			width: $list_mark_width;
			left: 0;
			transform: $skew;
		}
	}
}
