
.accordion{
	$acc_padding: 30px;
	.accordion_item{
		header{
			background: $sec_color;
			padding: 10px 20px 10px $acc_padding;
			position: relative;
			cursor: pointer;
			margin-bottom:2px;

			$icon_size:10px;

			span{
				width: $icon_size;
				height: $icon_size;
				position: absolute;
				right: $acc_padding - 10;
				top:0;
				bottom: 0;
				margin: auto;

				&:after, &:before{
					@extend %center_abs;
					content: '';
					width: $icon_size - 2;
					height: 1px;
					position: absolute;
					background: $prime_color;
				}
				&:before{
					transform: rotate(90deg);
				}
			}
		}
		&.active{
			span:before{
				display: none;
			}
			.accordion_item_content{
				display: block;
			}
		}
		.accordion_item_content{
			padding: 10px $acc_padding;
			padding-bottom: 14px;
			display: none;
		}
	}
}