.header{
	background: #FFF;
	box-shadow: 0px 4px 10px 0px rgba(97, 117, 132, 0.10);
	display: flex;
	justify-content: space-between;
	height: 84px;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 4;
	@media screen and (max-width: $tablet_ver){
		height: auto;
	}
	.logo{
		height: 26px;
		width: 138px;
		display: block;
		margin:auto;
		fill: $prime_color;
	}
	.left{
		margin-left: $page_padding;
		display: flex;
		align-items: center;
		@media screen and (max-width: $tablet_hor){
			margin-left: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		&:after{
			content: '';
			height: 100%;
			width: 1px;
			background: $gray_light;
			transform: $skew;
			@media screen and (max-width: $tablet_hor){
				display: none;
			}
		}
		@media screen and (max-width: $tablet_hor){
			.burger{
				margin: 0;
			}
			a{
				position: absolute;
				display: block;
				left: 60px;
				right: 60px;
				margin: auto;
			}
		}
	}
	.right{
		display: flex;
		@media screen and (max-width: $tablet_hor){
			display: none;
		}
	}
	.search{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 80px 0 30px;
		@media screen and (max-width: $tablet_hor){
			display: none;
		}
		input{
			padding: 32px 20px 30px 36px;

			font-size: 14px;
			display: block;
			flex:1 auto;
			background: url(../img/search_2.svg) left center no-repeat;
		}
	}
	.lang{
		font-size: 12px;
		font-weight: bold;
		color: $gray;
		display: flex;
		align-items: center;
		padding: 0 64px 0 34px;
		column-gap: 12px;
		position: relative;
	
		a{
			padding: 10px;
		}
		&:before{
			content: '';
			transform: $skew;
			position: absolute;
			top:0;
			bottom: 0;
			background: $gray_light;
			width: 1px;
			left: -22px;
		}
	}
	.right_btn{
		background: $prime_color;
		color: #fff;
		text-decoration: none;
		display: flex;
		align-items: center;
		padding: 0 30px;
		font-weight: bold;
		position: relative;
		&:before{
			content: '';
			background: $prime_color;
			width: 40px;
			transform: $skew;
			position: absolute;
			left: -18px;top:0;
			bottom: 0;
			transition: $transition_1;
		}
		&:hover{
			background: #8b101c;
			&:before{
				background: #8b101c;
			}
		}
		b{
			z-index: 2;
			white-space: nowrap;
		}
	}
	a{
		font-size: 13px;
		text-decoration: none;
	}
	.button_link{
		font-size: 11px;
		line-height: 1;
	}
	.search_link{
		width: 60px;
		height: 60px;
		background: url(../img/search_2.svg) center no-repeat;
		@media screen and (min-width: $tablet_hor) {
			display: none;
		}
	}

}
.map_header{
	@media screen and (max-width: $mobile){
		height: 50px;
	}
	.bread_crumbs{
		padding: 0;
		margin: 0 40px 0 0;
		width: 100px;
		@media screen and (max-width: $tablet_hor){
			margin: 0 0 0 $page_padding;
		}
	}
	.left{
		@media screen and (max-width: $tablet_hor){
			display: flex;
			height: 100%;
		}
		a{
			@media screen and (max-width: $tablet_hor){
				display: flex;
				position: relative;
			}
		}
	}
	.right{
		@media screen and (max-width: $tablet_hor){
			display: block;
			position: absolute;
			padding: 5px 0;
			top:50px;
			background: #fff;
			border-top: 1px solid $gray_light;
			left: 0;
			right: 0;
		}
	}
}