body{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
	height: 100%;
	&.no_scroll{
		overflow-y: hidden;
		height: 100% ;
	}
}
html{
	height: 100%;
}

.into{
	padding: 0 $page_padding;
	box-sizing: border-box;

	@media screen and (max-width: $mobile){
		padding: 0 $page_padding/2;
	}
	&.small{
		h1, h2, h3, ul, ol, p{
			max-width: $text_max_width;
			margin-left: auto;
			margin-right: auto;
		}
		margin: auto;
	}
}
.col_2{
	width: 50%;
	@media screen and (max-width: $mobile){
		width: 100%;
	}
}
.col_3{
	width: 33.33333%;
}
.col_3x2{
	width: 66.66666%;
}
.col_4{
	width: 25%;
	@media screen and (max-width: $mobile){
		width: 46%;
	}
}

.col_4x3{
	width: 75%;
}
@media screen and (max-width: $mobile){
	.col_3{
		width: 100%;
	}
}

.wrapper{
	display: flex;
	flex-direction: column;
	min-height: 100%;
}
.img_wr{
	img{
		display: block;
		width: 100%;
	}	
}