.ui_kit_container{
	padding: 0 10% 10%;

	h2{
		margin: 100px 0 40px 0;
		padding-top: 20px;
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.content{
	flex:1 1 auto;
	margin-top: 84px;
	@media screen and (max-width: $tablet_ver){
		margin-top: 56px;
	}
}

.bread_crumbs{
	margin: 30px 0 0 0;
	position: relative;
	z-index: 2;
	@media screen and (max-width: $tablet_hor) {
		margin: 20px 0 10px;
	}
	.into{
		display: flex;
		column-gap: 12px;
	}
	a{
		color: #6B727B;
		text-decoration: none;
		font-size: 12px;
		display: flex;
		align-items: center;
		column-gap: 12px;
		&:before{
			content: '';
			background: url(../img/arrow.svg);
			width: 5px;
			height: 14px;
			display: block;
		}
	}
}

.burger{
	width: 60px;
	height: 60px;
	position: relative;
	cursor: pointer;
	margin: 0 24px 0 16px;
	div{
		@extend %burger_after_before;
		&:after{
			@extend %burger_after_before;
			top:-14px;
		}
		&:before{
			@extend %burger_after_before;
			bottom:-14px;
			left: -8px;
			width: 16px;
		}
	}
	&:hover{
		div{
			width: 12px;
		}
	}
}

.ctn_dealers{
	background: $prime_color url(../img/map.svg) no-repeat center;
	color: #fff;
	padding: 95px 0 30px;
	position: relative;
	@media screen and (max-width: $tablet_hor) {
		padding: 30px 0;
	}

	header{
		border-bottom: 1px solid $red_light;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-bottom: 50px;
		@media screen and (max-width: $tablet_hor) {
			display: block;
			padding: 0 0 20px 0;
		}
		p{
			margin: 0;
			width: 33.3333%;
			@media screen and (max-width: $tablet_hor) {
				width: auto;
			}
		}
	}
	.search_wrapper{
		margin: 60px auto;
		max-width: 860px;
		border:2px solid #fff;
		padding: 6px;
		background: rgba(#fff, 0.2);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media screen and (max-width: $tablet_hor) {
			margin: 20px auto;
		}
		input{
			width: 80%;
			padding: 0 0 0 60px;
			background: transparent;
			color: #fff;
			font-size: 14px;
			box-sizing: border-box;
			background: url(../img/search.svg) no-repeat 20px center;
			&::placeholder {
				color: #fff;
			}
			@media screen and (max-width: $mobile) {
				padding: 10px 10px 10px 60px;
				width: 100%;
			}
		}
		.btn{
			margin: 0;
			background: $font_color;
			width: 20%;
			text-align: center;
			display: block;
			border: none;
			color: #fff;
			&:hover{
				background: black;
			}
			@media screen and (max-width: $mobile) {
				display: none;
			}
		}
	}
	.dec{
		background: rgba(#fff, .4);
		position: absolute;
		top:0;
		left: 50%;
		height: 50px;
		width: 30px;
		transform: $skew;
		&:after{
			content: '';
			height: 100px;
			width: 3px;
			background: #fff;
			position: absolute;
			left: -12px;
		}
		@media screen and (max-width: $mobile) {
			left: 80%;
		}
	}
}

.ctn_support{
	// background: $font_color;
	background-position: top left;
	color: #fff;
	padding: 95px 0;
	border-top: 4px solid #2B2F35;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: $tablet_hor) {
		padding: 20px 0;
	}
	// .support_pattern{
	// 	background: $font_color url(../img/pattern1.png) ;
	// 	background-size: 300px;
	// 	content: "";
	//     position: absolute;
	//     width: 200%;
	//     height: 200%;
	//     top: -50%;
	//     left: -50%;
	//     z-index: -1;
	//     transform: rotate(30deg);
	//     @media screen and (max-width: $tablet_hor) {
	//     	left: -140%;
	//     	height: 250%;
	//     	width: 220%;
	//     	top:-20%;
	//     }
	// }
	.support_pattern{
		background: $font_color url(../img/pattern1.png) ;
		background-size: 300px;
		content: "";
	    position: absolute;
	    top:0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    z-index: -1;
	}
	header{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@media screen and (max-width: $tablet_hor) {
			display: block;
		}
	}
	.grid_wrapper{
		display: flex;
		justify-content: space-between;
		column-gap: 2px;
		counter-reset: section;
		@media screen and (max-width: $tablet_hor) {
			flex-direction: column;
		}

		.item{
			background: $gray_light;
			font-family:$font_titles;
			color: $font_color;
			flex:auto;
			text-align: center;
			font-size: 24px;
			padding: 20px 0;
			line-height: 1;
			position: relative;
			overflow: hidden;
			@media screen and (max-width: $tablet_hor) {
				margin: 0 0 2px 0;
			}
			b{
				position: relative;
			}
			&:before{
				counter-increment: section 1;
				content: "0" counter(section);
				font-weight: bold;
				position: absolute;
				left: -6%;
				top:-20px;
				font-size: 134px;
				color: #fff;
			}
		}
	}
	.support_contacts{
		display: flex;
		padding: 80px 0 0 0;
		column-gap: 40px;
		@media screen and (max-width: $tablet_hor) {
			flex-direction: column;
		}
		.item{
			line-height: 1;
			width: 33.333%;
			font-family: $font_titles;
			font-weight: bold;
			font-size: 24px;
			@media screen and (max-width: $tablet_hor) {
				width: auto;
				margin-bottom: 20px;
			}
			header{
				color: $gray;

			}
			.title{
				font-size: 50px;
				margin: 0;
				padding: 0;
				line-height: 1;
			}
			a{
				color: #fff;
				text-decoration: none;
			}
		}
	}

}

.ctn_cert{
	background: $gray_light;
	padding: 95px 0;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: $tablet_hor) {
		padding: 30px 0;
	}
	.column{
		width: 33.33333%;
		position: relative;
		z-index: 2;
		padding: 0 40px 0px 0;
		box-sizing: border-box;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
			padding: 0;
		}
	}
	.dec{
		position: absolute;
		background: #fff;
		opacity: 0.4;
		transform: $skew;
		width: 500px;
		top:0;
		bottom: 0;
		left: 24%;
	}
	.dec_2{
		position: absolute;
		background: #fff;
		transform: $skew;
		width: 4px;
		bottom: 0;
		right: 34%;
		height: 160px;
		&:after{
			width: 32px;
			content: '';
			height: 40px;
			right: -40px;
			position: absolute;
			background: #fff;
			bottom: 0;
		}
	}
	.right{
		width: 66.6666%;
		// overflow: hidden;
		min-height: 500px;
		position: relative;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
			min-height: auto;
		}
	}
	.into{
		display: flex;
		padding: 0;
		padding-left: $page_padding;
		@media screen and (max-width: $tablet_hor) {
			flex-direction: column;
			padding: 0 $page_padding/2;
		}
	}
	blockquote{
		position: relative;
		max-width: 100%;
		padding: 36px 0 30px;
		padding-right: $page_padding*1.3;
		padding-left: 44%;
		border:none;
		margin: 0;
		@media screen and (max-width: $tablet_hor) {
			padding: 60px 0 0 0;
			position: revert;
		}
		&:after{
			content: '';
			left: 20%;
			right: -10%;
			top:0;
			bottom: 0;
			background: $prime_color;
			position: absolute;
			transform: $skew;
			@media screen and (max-width: $tablet_hor) {
				height:200px;
				right: 15%;
				left: 15%;

			}
		}
		&:before{
			content: '';
			position: absolute;
			width: 2px;
			height: 80px;
			background: $prime_color;
			transform: $skew;
			top:0px;
			left: 20%;
			@media screen and (max-width: $tablet_hor) {
				left: 15%;
			}

		}
		span{
			position: relative;
			z-index: 2;
			color: #fff;
			@media screen and (max-width: $tablet_hor) {
				color: $font_color;
			}
		}
	}
	.cert{
		margin-top: 180px;
		position: relative;
		@media screen and (max-width: $tablet_hor) {
			margin-top: 10px;
		}
		.sl_item{
			position: absolute;
			left: 20%;
			top:30px;
			display: block;
			border: 5px solid $font_color;
			background: #FFF;
			box-shadow: 0px 13px 27px 0px rgba(97, 117, 132, 0.20), 0px 2.7px 2.7px 0px rgba(0, 0, 0, 0.25) inset;
			z-index: 2;
			padding: 22px 16px;
			width: 20%;
			box-sizing: border-box;
			@media screen and (max-width: $tablet_hor) {
				left:auto;
				right:auto;
				width: auto;
				margin: auto 20%;
				max-width: 300px;
				position: relative;
			}
			img{
				display: block;
				width: 100%;
			}
		}
	}
	&.big{
		padding-bottom: 0;
	}
	.bot{
		background: $prime_color;
		width: 380px;
		padding: 40px;
		padding-left: $page_padding;
		color: #fff;
		z-index: 2;
		position: relative;
		margin-top: 90px;
		@media screen and (max-width: $mobile) {
			padding: 30px;
			box-sizing: border-box;
		}
		&:after{
			position: absolute;
			top:0;
			bottom: 0;
			content: '';
			width: 300px;
			transform: $skew;
			right: -80px;
			background: $prime_color;
			z-index: -1;
		}
	}
}

.ctn_about{
	position: relative;
	background: url(../img/bg_rad.png) no-repeat;
	background-position: -40% center;
	padding: 95px 0 120px;
	overflow: hidden;
	@media screen and (max-width: $tablet_hor) {
		// display: none;
		padding: 30px 0 120px;
		background-position:center -100px ;
		overflow: hidden;
	}
	.into{
		display: flex;
		column-gap: 40px;
		@media screen and (max-width: $tablet_hor) {
			flex-direction: column;
		}
	}
	.column{
		position: relative;
		width: 30%;
		box-sizing: border-box;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
		}
	}
	.lay{
		z-index: 2;
	}
	.item_1{
		@media screen and (max-width: $tablet_hor) {
			height: 500px;
			.num{
				font-size: 14em;
				text-align: center;
			}
			.title{
				font-size: 30px;
				line-height: 0.9;
				bottom: auto;
			}
		}
	}
	.item_2{
		@media screen and (max-width: $tablet_hor) {
			order: 2;
			margin-top: 50px;
		}
	}
	.deco{
		position: absolute;
		top:0;
		bottom:0;
		width: 400px;
		transform: $skew;
		z-index: 2;
		background: #fff;
		left: 24.5%;
		@media screen and (max-width: $tablet_hor) {
			left: 23%;
		}
	}
	.dec{
		position: absolute;
		transform: $skew;
		background: $prime_color;
		right: 25%;
		width: 4px;
		height: 50px;
		top: 0;
		@media screen and (max-width: $tablet_hor) {
			display: none;
		}
	}
	
	.into{
		display: flex;
		column-gap: 40px;
		position: relative;
		.p_min{
			padding-right: 50px;
			@media screen and (max-width: $tablet_hor) {
				padding: 0;
			}
		}
	}
	.number_1{
		position: absolute;
		right: $page_padding - 1;
		font-size: 326px;
		bottom: -90px;
		color: $gray_light;
		@media screen and (max-width: $tablet_hor) {
			// position: relative;
			font-size: 12em;
			line-height: 1;
			right: 0;
			left: 0;
			margin: auto;
			text-align: center;
			bottom: -190px;
			z-index: 2;

		}
	}


	&.uniq{
		padding: 50px 0;
		margin-top: -200px;
		@media screen and (max-width: $tablet_hor) {
			margin-top: -170px;
			overflow-y: visible;
			padding-top: 500px;
			.deco{
				left: 35%;
			}
		}
		.into{
			@media screen and (max-width: $tablet_hor) {
				padding: 0;
				.column{
					top:-220px;
					.num{
						font-size: 223px;
						text-align: center;

					}
					.title{
						font-size: 30px;
						line-height: 28px;
						bottom: -200px;
						right: 30px;
					}
				}
			}
		}
		.right{
			position: relative;
			z-index: 2;
			width: 66.66666%;
			padding-left: 40px;
			box-sizing: border-box;
			@media screen and (max-width: $tablet_hor) {
				width: 100%;
				padding: 0;
			}
			.numbers{
				margin-top: 120px;
				padding: 0 $page_padding/2;
				@media screen and (max-width: $mobile) {
					margin-top: 30px;
					.item{
						width: 50%;
						padding: 20px 0;
						padding-right: 20px;
						box-sizing: border-box;
					}
				}
			}
			.qu{
				background: $prime_color;
				color: #fff;
				padding: 45px;
				max-width: 515px;
				font-family: $font_titles;
				font-weight: bold;
				font-size: 30px;
				line-height: 30px;
				margin-top: 24px;
				transform: $skew translate(90px, 0);
				@media screen and (max-width: $tablet_hor) {
					transform: none;
				}
				p{
					padding: 0 40px;
					margin: 0;
					transform: skew(24deg);
					@media screen and (max-width: $tablet_hor) {
						transform: none;
						padding: 0;
					}
				}
			}
		}
	}
}
.numbers{
	display: flex;
	flex-wrap: wrap;
	.item{
		font-size: 12px;
		line-height: 14px;
		text-transform: uppercase;
		width: 33.3333%;
		box-sizing: border-box;
		padding: 16px 0;
		padding-right: 40px;

		.title{
			margin: 0;
			padding: 0;
		}
	}
}


.ctn_home{
	height: 100%;
	display: flex;
	padding-left: $page_padding;
	align-items: stretch;
	overflow: hidden;
	@media screen and (max-width: $tablet_hor) {
		display: block;
		padding: 30px $page_padding/2 0;
	}

	&.dop{
		height: 460px;
		@media screen and (max-width: $tablet_hor) {
			height: auto;
		}
		.home_links{
			@media screen and (max-width: $tablet_hor) {
				padding: 0 0 30px 0;
			}
		}
	}

	.home_links{
		display: flex;
		align-items: stretch;
		width: 100%;
		@media screen and (max-width: $tablet_hor) {
			display: block;
			padding: 30px 0;
		}
	}
	.left{
		width: 50%;
		display: flex;
		align-items: center;
		h1{
			margin: 0;
			padding: 0;
			// @media screen and (max-width: $tablet_hor) {
			// 	padding-top: 30px;
			// }
		}
	}

	.item{
		display: flex;
		align-items: flex-end;
		width: 25%;
		background: black;
		color: #fff;
		transform: $skew;
		text-decoration: none;
		overflow: hidden;
		@media screen and (max-width: $tablet_hor) {
			width: 100%;
			transform: skew(0);
			overflow: hidden;
		}
		.back{
			position: absolute;
			transform: skew(24deg);
			left: -70%;
			top:0;
			bottom: 0;
			background-size: cover;
			background-position: left center;
			width: 300%;
			opacity: 0.8;
			transition: $transition_1;
			@media screen and (max-width: $tablet_hor) {
				transform: skew(0);
				left: 0;
				width: 100%;
			}
		}
		&:nth-child(1) .back{
			background-image: url(../img/01.jpg);
		}
		&:nth-child(2) .back{
			background-image: url(../img/02.jpg);
		}
		&:nth-child(3) .back{
			background-image: url(../img/03.jpg);
		}
		&:nth-child(4) .back{
			background-image: url(../img/04.jpg);
		}
		&:hover{
			@media screen and (min-width: $mobile) {
				width: 30%;
				.back{
					opacity: 1;
				}
			}
		}
		.into{
			transform: skew(24deg) translate(-4px);
			padding: 0px 0px;
			line-height: 14px !important;
			height:210px;
			display: flex;
			flex-direction: column;
			width: 200px;
			@media screen and (max-width: $tablet_hor) {
				transform: $skew translate(10px);
				overflow: hidden;
				width: 100%;
				padding: 10px 0;
				height: auto;
			}
			h2{
				font-size: 24px;
				line-height: 30px;
				@media screen and (max-width: $tablet_hor) {
					transform: skew(24deg) translateX(30px);
					margin: 0;

				}
				@media screen and (max-width: $tablet_hor + 160) {
					font-size: 22px;
				}
			}
			.num{
				font-size: 220px;
				line-height: 120px;
				font-family: $font_titles;
				transform: translate(-8px);
				font-weight: 500;
				color:#fff;
				@media screen and (max-width: $tablet_hor) {
					transform: skew(24deg) translateX(-16px);
				}
			}
		}
		.description{
			font-size: 10px;
			text-transform: uppercase;
			@media screen and (max-width: $tablet_hor) {
				transform: skew(24deg) translateX(48px);
			}
		}
	}
}


.contact_grid{
	display: flex;
	flex-wrap: wrap;
	column-gap: 40px;
	padding-bottom: 80px;
	padding-top: 30px;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: $tablet_ver){
		display: block;
		padding-top: 0;
	}
	.item{
		width: 31%;
		padding: 24px;
		background: #fff;
		box-shadow: 0px 10px 20px 0px rgba(97, 117, 132, 0.08);
		box-sizing: border-box;
		line-height: 22px;
		font-size: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 20px 0;
		position: relative;
		@media screen and (max-width: $tablet_ver){
			width: auto;
		}
		header{
			font-family: $font_titles;
			font-weight: bold;
			font-size: 20px;
			line-height: 1;
			padding:0 0 16px 0;
			border-bottom: 1px solid $gray_light;
			margin-bottom: 20px;
		}
		.tel{
			font-family: $font_titles;
			font-weight: bold;
			font-size: 28px;
		}
		a{
			text-decoration: none;
			font-size: 14px;
		}
		.ctn{
			div{
				margin: 8px 0 0px 0;
			}
		}
	}
	.bg_rad{
		transform: scale(2.7);
		top:18%;
		right: -5%;
		@media screen and (max-width: $tablet_ver){
			top:7%;
		}
		&:nth-child(1){
			left:8%;
			right: auto;
			bottom: 0%;
			top:auto;
			transform: scale(3.2);

		}
	}
}

.date{
	font-size: 10px;
	text-transform: uppercase;
	background: $gray_light;
	padding: 0 4px;
	color: $gray;
	display: inline-block;
	margin-top: 20px;
	line-height: 1.6;
	& + h1{
		padding: 24px 0 0 0;
		@media screen and (max-width: $tablet_hor) {
			padding-top: 10px;
		}
	}
}

.ctn_coaching{
	padding: 80px 0 120px 0;
	@media screen and (max-width: $tablet_hor) {
		padding: 40px 0;
	}
	.into{
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: $tablet_hor) {
			display: block;
		}
	}
	ul{
		max-width: 620px;
		margin-top: 68px;
		p{
			margin-top: 8px;
		}
	}
}
.ctn_history{
	padding: 90px 0;
	.into{
		display: flex;
		// flex: auto 1;
		justify-content: space-between;
		column-gap: 40px;
		.ctn{
			width:66.3333%;
			border: 1px solid red;
		}
		.col_3{
			flex: auto 1;
		}
	}
	&.hero{
		.block{
			position: relative;
		}
	}
}

.hero.hist{
	display: block;
	height: auto;
	padding: 90px 0 30px;
	@media screen and (max-width: $tablet_hor) {
		padding: 30px 0;
	}
	.into{
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: $tablet_hor) {
			display: block;
		}
	}
	.block{
		position: relative;
		top:auto;
		width: 55%;
		overflow: visible;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
			height: 340px;
		}
		.num{
			right: 0;
			@media screen and (max-width: $tablet_hor) {
				left: 140px;
				z-index: 2;
				bottom: 10px;
				top:auto;
			}
		}
		.back{
			background: $font_color;
			width: 500px;
			height: 500px;
			@media screen and (max-width: $tablet_hor) {
				width: 140px;
				height: 300px;
				left: 20%;
			}
			&:before{
				background: $prime_color;
				top:0;
				width: 30px;
				@media screen and (max-width: $tablet_hor) {
					display: block;
				}
			}
		}
		.front{
			@media screen and (max-width: $tablet_hor) {
				right: auto;
				left: 55%;
			}
		}
		img{
			right: 20%;
			@media screen and (max-width: $tablet_hor) {
				width: 300px;
			}
		}
	}
}


.ctn_news_wrapper{
	padding-left: $page_padding*2;
	padding-bottom: 80px;
	@media screen and (max-width: $tablet_hor) {
		padding: 0;
		width: auto;
		.ctn_scroll{
			overflow-x: scroll;
		}
	}
	.ctn_news{
		padding-left: $page_padding*2;
		justify-content: space-between;
		padding-bottom: 20px;
		@media screen and (max-width: $tablet_hor) {
			padding-left: $page_padding/2;
			display: inline-flex;
			flex-direction: row;
			flex-wrap: nowrap;
			.item{
				width: 300px;
			}
		}

	}
	.btn_wrapper{
		padding-left: $page_padding*2;
		@media screen and (max-width: $tablet_hor) {
			padding-left: $page_padding/2;
		}
	}
}

.ctn_news{

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 3%;
	padding-bottom: 120px;
	padding-top: 40px;
	justify-content: flex-start;
	@media screen and (max-width: $tablet_hor) {
		display: block;
		padding-top: 10px;
	}
	.item{
		background: #FFF;
		box-shadow: 0px 10px 22px 0px rgba(97, 117, 132, 0.10);
		box-sizing: border-box;
		text-decoration: none;
		color: $font_color;
		margin: 20px 0;
		flex: 1 1 1;
		height: auto;
		display: flex;
		flex-direction: column;
		width: 22.6%;
		position: relative;
		overflow: hidden;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
		}
		&:after{
			content: '';
			background: $gray_light;
			width: 10px;
			height: 20px;
			transform: $skew;
			position: absolute;
			bottom: 0;
			right: 15%;
		}
		&:before{
			content: '';
			background: $gray_light;
			width: 2px;
			height: 40px;
			transform: $skew;
			position: absolute;
			bottom: 0;
			right: 19%;
		}
		img{
			display: block;
			width: 100%;
			filter: grayscale(100%);
			transition: $transition_1;
		}

		h2{
			font-size: 20px;
			font-weight: 500;
			line-height: 1;
			display: block;
			width: 100%;
			padding: 0;
			margin: 0 0 16px 0;
		}
		.ctn{
			padding: 24px;
			padding-bottom: 20px;
			display: block;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			span{
				display: block;
				line-height: 1;
				padding: 0;
			}
		}
		&:hover{
			transform: scale(1.04);
			box-shadow: 0px 20px 40px 0px rgba(97, 117, 132, 0.2);
			z-index: 2;
			img{
				filter: grayscale(0%);
			}
		}
	}
	.date{
		margin: 0;
	}
}

.bg_rad{
	background: url(../img/bg_rad.png) center;
	background-size: 100%;
	position: absolute;
	width: 400px;
	height: 400px;
}

.hero_head{
	margin-top: 20px;
	z-index: 2;
	position: relative;
}

.hero{
	display: flex;
	justify-content: flex-end;
	height: 200px;
	@media screen and (max-width: $tablet_hor) {
		overflow-x: hidden;
		height:390px;
	}
	.bg{
		margin: -150px auto 0;
		transform: scale(2);
		@media screen and (max-width: $tablet_hor) {
			transform: scale(1) translate(-100px, 20px);
			margin: auto;
			width: 100%;
			// display: none;
		}
	}
	.block{
		overflow: hidden;
		position: absolute;
		top:80px;
		width: 100%;
		height: 620px;
		@media screen and (max-width: $tablet_hor) {
			top:auto;
			height: inherit;
		}
		.num{
			font-family:$font_titles;
			font-weight: 500;
			font-size: 240px;
			position: absolute;
			right: 8%;
			z-index: 2;
			bottom: 100px;
			color: $gray_light;
			line-height: 1;
			@media screen and (max-width: $tablet_hor) {
				right: auto;
				font-size: 138px;
				top:0;
				left:2%;
				z-index: 1;
			}
			&:after{
				content: '';
				background: $gray_light;
				transform: $skew;
				position: absolute;
				height: 78px;
				width: 2px;
				bottom: -10px;
				right: -10px;
				@media screen and (max-width: $tablet_hor) {
					display: none;
				}
			}
		}
		img{
			position: absolute;
			top:20px;
			right: 5%;
			z-index: 2;
			@media screen and (max-width: $tablet_hor) {
				top:auto;
				left: -10px;
				width: 140%;
			}
		}
		.back{
			background: $gray;
			width:600px;
			transform: $skew;
			right: 0;
			top:0;
			bottom: 30px;
			position: absolute;
			@media screen and (max-width: $tablet_hor) {
				width: 220px;
			}
			&:before{
				content: '';
				position: absolute;
				left: -50px;
				background: $gray_light;
				top:40px;
				width: 12px;
				height: 142px;
				@media screen and (max-width: $tablet_hor) {
					display: none;
				}
			}
		}
		.front{
			right: -25px;
			background: #fff;
			width:280px;
			top:0;
			bottom: 0;
			position: absolute;
			transform: $skew;
			z-index: 2;
			@media screen and (max-width: $tablet_hor) {
				width: 140px;
				right: -35%;
			}
		}
		.dec{
			bottom: 0;
			right: 380px;
			position: absolute;
			background: $prime_color;
			width: 34px;
			height: 100px;
			transform: $skew;
			z-index: 3;
			@media screen and (max-width: $tablet_hor) {
				right: 40px;
				height: 80px;
				bottom: -20px;
			}
		}
	}
}


#menu{
	width: 400px;
	@media screen and (max-width: $tablet_hor) {
		width: 100%;
	}
	.modal_content_wrapper{
		margin: 0;
		.modal_content{
			background: $prime_color;
			animation: menu_ani 0.3s forwards cubic-bezier(.165,.84,.44,1);
			display: flex;
			.into{
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 40px;
			}
		}
		.cross_close{
			width: 65px;
			height: 65px;
		}
	}
	nav{
		counter-reset: section;
		a{
			display: block;
			color: #fff;
			font-size: 11px;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
			padding: 6px 0;

			&:before{
				color: $red_light;
				font-size: 12px;
				margin-right: 10px;
				content: "0" counter(section);
				counter-increment: section;
				transition: $transition_1;
			}
			&:hover{
				// padding-left: 12px;
				&:before{
					margin-right: 20px;
				}
			}
		}
	}
	.cross_close{
		&:before, &:after{
			background: #fff;
		}
	}
	.lang_selector{
		display: flex;
		margin: 20px 0 0 0;
		column-gap: 10px;
		a, span{
			display: flex;
			border: 1px solid $red_light;
			align-items: center;
			height:44px;
			width: 46px;
			color: #fff;
			font-size: 10px;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
			justify-content: center;
		}
		span{
			opacity: 0.6;
		}
	}
}
@keyframes menu_ani{
	0%{
		transform: translateX(-100px) scale(1);
		opacity: 0;
	}

}

.systems{
	display: flex;
	justify-content: flex-start;
	column-gap: 2%;
	flex-wrap: wrap;
	padding-top: 40px;
	padding-bottom: 80px;
	@media screen and (max-width: $tablet_hor) {
		flex-direction: column;
		padding-top: 0px;
	}

	.item{
		border: 1px solid $gray_light;
		text-align: center;
		position: relative;
		padding: 0px;
		width: 23.5%;
		box-sizing: border-box;
		margin: 16px 0;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
			margin: 8px 0;
			// display: flex;
			padding: 0;
		}
		a.system_img_link{
			display: block;
			transition: $transition_1;
			padding-bottom: 120px;
			z-index: 2;
			position: relative;
			@media screen and (max-width: $tablet_hor) {
				align-items: center;
				text-decoration: none;
				width: 100%;
				padding: 0;
			}
		}
		
		svg{
			fill: #000;
			width: 130px;
			height: 130px;
			margin: 40px auto 0;
			display: block;
			transition: $transition_1;
			transform: scale(1.1);
			@media screen and (max-width: $tablet_hor) {
				margin: 0;
				transform: scale(1);
			}
			
		}
		.title{
			font-size: 24px;
			font-weight: bold;
			margin: 0;
			padding: 0 24px;
			max-width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;
			color: $black;
			transition: $transition_1;
			line-height: 1;
			box-sizing: border-box;
			text-decoration: none;
			.button_link{
				font-size: 10px;
				font-weight: bold;
				display: block;
				margin: auto;
				font-family: 'Pragmatica', sans-serif;
				z-index: 2;
			}
			@media screen and (max-width: $tablet_hor) {
				// position: relative;
				text-align: left;
				left: 35%;
				right: auto;
				width: 65%;
				.button_link{
					text-align: left;
					margin: 0;
					padding-top: 8px;
				}

			}
		}

		&:hover{
			svg{
				fill: $prime_color;
			}
			.title{
				color: $prime_color;
			}
		}
	}
}

.ctn_systems{
	background: $prime_color;
	color: #fff;
	padding: 95px 0;
	position: relative;
	@media screen and (max-width: $tablet_hor) {
		padding: 40px 0;
	}
	.item{
		border-color: $red_light;
		transition: $transition_1;
		svg{
			fill:#fff;
		}
		.title{
			color: #fff;
		}
		.button_link{
			color: #fff;
			&:after{
				background: #fff;
			}
		}
		&:hover{
			svg{
				fill:#fff;
			}
			.title{
				color: #fff;
			}
		}
	}

	&.red{
		.title{
			padding-top: 0px;
			&:after{
				background-color: #fff;
			}
		}
		.item{
			&:hover{
				transform: scale(1.04);
				box-shadow: 0px 20px 40px 0px rgba(#8C0714, 0.2);
				z-index: 2;
				border-color: #fff;
			}
		}
	}

	&.two{
		display: flex;
		column-gap: 40px;
		padding: 95px $page_padding;
		@media screen and (max-width: $tablet_ver){
			display: block;
			padding: 40px $page_padding/2;
		}
		.into{
			padding: 0;
			&:first-child{
				order: 2;
				width: 33.3333%;
			}
			&.systems{
				width: 66.6666%;
				column-gap: 4%;
				@media screen and (max-width: $tablet_ver){
					width: auto;
				}
				.item{
					width: 30.6666%;
					@media screen and (max-width: $tablet_hor){
						width: auto;
						.title{
							text-align: left;
							left: 35%;
							right: auto;
							width: 65%;

							padding: 0 24px;
							margin: 0;
						}
					}
				}
			}
		}
	}
	.dec{
		background: rgba(#fff,0.4);
		position: absolute;
		width: 30px;
		height: 50px;
		bottom: 0;
		right: 15%;
		transform: $skew;
		&:before{
			content: '';
			background: #fff;
			width: 3px;
			height: 140px;
			color: white;
			bottom: 0;
			position: absolute;
			left: -15px;
		}
	}
}

.tdec{
	&:after{
		content: '';
		transform: $skew translate(30px, 6px);
		display: inline-block;
		height: 78px;
		background: $prime_color;
		width: 2px;
		position: absolute;
		@media screen and (max-width: $tablet_hor){
			height: 50px;
			transform: $skew translate(30px, 4px);
		}
	}
}

.num_ctn{
	position: absolute;
	top:0;
	bottom: 0;
	margin: auto;
	height: 0;
	left: 0;
	right: 0;
	.title{
		line-height: 48px;
		font-size: 50px;
		color: $prime_color;
		position: absolute;
		bottom: -270px;
		right: 20px;
		z-index: 2;
	}
}

.num{
	font-size: 375px;
	font-family: $font_titles;
	font-weight: bold;
	line-height: 0.5;
	color: $prime_color;
	z-index: 1;
}

.cou{
	// border: 1px solid white;
	padding: 0 40px;
	@media screen and (max-width: $mobile) {
		padding: 0;
	}

	.item{
		display: inline-flex !important;
		align-items: center;
		height: 100px;
		margin: 0;
		// padding: 20px;
		box-sizing: border-box;
		@media screen and (max-width: $mobile) {
			height: 60px;
		}
		img{
			display: block;
			margin:0 30px;
			@media screen and (max-width: $mobile) {
				margin: 0 10px;
			}
		}
	}
	.slick-prev{
		left: 0;
		&:before{
			content: '';
			background: url(../img/arrow_2.svg) no-repeat center ;
			top:0;
			bottom: 0;
			margin: auto;
			left: -2px;
			position: absolute;
			right: 0;
			transform: rotate(-180deg);
		}
	}
	.slick-next{
		right: 0;
		&:before{
			content: '';
			background: url(../img/arrow_2.svg) no-repeat center ;
			top:0;
			bottom: 0;
			margin: auto;
			left: 2px;
			position: absolute;
			right: 0;
		}
	}
	.slick-arrow{
		border: 2px solid $red_light;
		width: 44px;
		height: 44px;
		@extend %radius_100;
		background: $prime_color;
		z-index: 2;
		@media screen and (max-width: $mobile) {
			display: none !important;
		}
	}
}

.cat_menu{
	margin: 0;
	li{
		padding: 0;
		margin: 0;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: bold;
		line-height: 12px;
		&.active{
			border-left:1px solid $prime_color;
			span{
				color: $prime_color;
				position: relative;
				&:before{
					content: '';
					width: 3px;
					background: $prime_color;
					left: 0;
					top:0;
					bottom: 0;
					position: absolute;
				}
			}
		}
		&:before{
			display: none;
		}
	
		a, span{
			color: $font_color;
			text-decoration: none;
			display: block;
			padding: 14px 24px;
		}
		ul{
			padding: 10px 0;
		}
		li{
			font-size: 14px;
			text-transform: none;
			font-weight: normal;
			&.active{
				border: none;
			}
			a, span{
				padding: 6px 0;
				padding-left: 36px;
				background: url(../img/arrow-g.svg) 24px center no-repeat;
				line-height: 16px;
				&:before{
					display: none;
				}
			}
			span{
				background-image: url(../img/arrow-r.svg);
			}
		}
	}
}

.waside{
	display: flex;
	column-gap: 40px;
	margin: 36px 0 100px;
	justify-content: space-between;
	@media screen and (max-width: $tablet_hor) {
		flex-direction: column;
		margin: 20px 0 40px;
	}
	h1{
		padding-top: 0;
	}
	.ctn{
		border: 1px solid $gray_light;
		padding: 40px 5% 40px;
		width: 75%;
		box-sizing: border-box;
		@media screen and (max-width: $tablet_hor) {
			padding: 0;
			border: none;
			width: auto;
		}
	}
	&.search_result{
		.ctn{
			border: none;
			padding: 0;
		}
	}
	aside{
		width: 25%;
		@media screen and (max-width: $tablet_hor) {
			width: auto;
			border: 1px solid $gray_light;
			margin-top: 10px;
		}
	}

}

.specs{
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	text-align: center;
	td{
		padding: 16px 24px;
		span{
			white-space: nowrap;
		}
	}
	thead{
		font-size: 12px;
		line-height: 14px;
	}
	&.oem{
		max-width: 500px;
	}
	&.links{
		tr:hover{
			background: $gray_light;
			cursor: pointer;
		}
	}
}

.product_top{
	display: flex;
	column-gap: 40px;
	padding-bottom: 20px;
	padding-top: 24px;
	@media screen and (max-width: $tablet_hor) {
		flex-direction: column;
		overflow: hidden;
	}
	h1{
		margin: 0;
		padding: 0;
	}
}
.p_img_ctn, .p_desc{
	width: 30%;
	@media screen and (max-width: $tablet_hor) {
		width: auto;
	}
}
.p_desc{
	width: 70%;
}
.p_img_ctn{
	padding-top: 5px;
	@media screen and (max-width: $tablet_hor) {
		margin-bottom: 20px;
	}
	.p_img_slider{

		.item{
			img{
				display: block;
				width: 100%;
			}
		}
		.slick-dots li{
			margin: 0;
			&.slick-active button:before{
				color:$prime_color;
			}
		}
	}
}
.de_flex_ctn{
	padding: 10px 0;
	margin: 10px 0;
	max-width: $text_max_width;
}
.de_flex{
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
	border-bottom: 1px solid $border_color;
}
.art{
	margin: 0px 0 10px 0;
}

.market_links{
	display: flex;
	column-gap: 20px;
	$market_links_size:40px;
	a{
		display: block;
		overflow: hidden;
		width:$market_links_size;
		height:$market_links_size;
		img{
			display: block;
			width: 100%;
			filter: grayscale(100%);
			transition: $transition_1;
		}
		&:hover{
			img{
				filter: grayscale(0%);
			}
		}
	}
}
.product_bot{
	padding-bottom:80px;
	.market_links{
		a{
			width: 60px;
			height: 60px;
		}
	}
}

.title_d{
	height: 55px;
	width: 2px;
	background: $prime_color;
	display: inline-block;
	transform: $skew translate(4px, 2px);
	margin: 0 14px;
	@media screen and (max-width: $tablet_hor) {
		height: 32px;
	}
}

.search_modal{
	background: #fff;
	border-top: 1px solid $gray_light;
	padding: 16px 0;
	top:84px;
	font-size: 14px;
	bottom: 0;
	position: fixed;
	left: 0;
	right: 0;
	z-index: 4;
	overflow-x: scroll;
	@media screen and (max-width: $tablet_hor) {
		top:60px;
	}
	.into{
		max-width: 900px;
		width: 100%;
		margin:0 auto;
	}
	.search_links{
		padding: 10px 0;
		
		a{
			display: block;
		}
		
	}
}

.search_tags{
	padding-bottom: 10px;
	.item{
		display: inline-flex;
		background: $prime_color;
		color: #fff;
		padding: 3px 8px;
		padding-right: 3px;
		border-radius: 100px;
		align-items: center;
		line-height: 12px;
		margin: 0 5px 0 0;
	}
	.close{
		background: $gray_light;
		width: 18px;
		height: 18px;
		border-radius: 100%;
		margin-left: 6px;
		position: relative;
		cursor: pointer;
		&:after, &:before{
			width: 1px;
			content: '';
			left: 0;
			right: 0;
			top:0;
			bottom: 0;
			height: 10px;
			background: $font_color;
			position: absolute;
			margin: auto;
			transform: rotate(45deg);
		}
		&:before{
			transform: rotate(-45deg);
		}
		&:hover{
			background: $prime_color;
			&:after, &:before{
				background: #fff;
			}
		}
	}
}

.marks{
	padding: 10px 0;
	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
		column-count: 3;
		column-gap: 20px;
		@media screen and (max-width: $tablet_hor) {
			column-count: 2;
		}
		li{
			margin: 0;
			font-size: 14px;
			line-height: 22px;
			position: relative;
			padding: 0px 0 0 24px;
			&.clear{
				height: 16px;
			}
			span{
				border-left: 1px solid $prime_color;
				display: block;
				padding: 3px 10px;
				cursor: pointer;
				&:hover{
					color: $prime_color;
				}
			}


			&:before{
				content: attr(data-rel);
				transform: skew(0);
				background: none;
				position: absolute;
				font-weight: bold;
				font-size: 16px;
				line-height: 14px;
				margin-top: 5px;
				color: $prime_color;

			}
			&.off:before{
				content: '';
			}
		}
	}
}

.filter{
	display: flex;
	height: 100%;
	align-items: center;
	column-gap: 16px;
	padding: 0 40px;
	position: relative;
	&:before{
		content: '';
		left: 0;
		width: 1px;
		height: 100%;
		position: absolute;
		background: $gray_light;
		transform: $skew;
	}
	.checkbox_wrapper{
		font-size: 12px;
		line-height: 1;
		white-space: nowrap;
		color: $font_color;
		font-weight: normal;
		line-height: 16px;
		span{
			width: 16px;
			height: 16px;
			&:after{
				width: 16px;
				height: 16px;
			}
		}
	}
	
}


.map_bar{
	width: 20%;
	background: $gray_light;
	overflow-x: scroll;
	position: fixed;
	bottom: 0;
	top:84px;
	@media screen and (max-width: $mobile) {
		width: 100%;
		top:93px;
		bottom: auto;
	}
	nav{
		padding-top: 48px;
		@media screen and (max-width: $mobile){
			display: none;

		}
		.item{
			font-size:14px;
			line-height: 1.3;
			padding: 16px;
			margin: 0 0 1px 0;
			background: #fff;
			cursor: pointer;
			transition: $transition_1;
			&:hover{
				background: $gray_light;
			}
			&.active{
				background: $prime_color;
				color: #fff;
				.name:before{
					background: #fff;
				}
			}
			b{
				margin: 0 0 4px 0;
				display: block;
			}
			
			.address{
				font-size: 12px;
				line-height: 15px;
				overflow:hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 2px 0 0 0;
			}
		}
	}
	.market_links{
		position: fixed;
		padding: 4px 0;
		justify-content: center;
		margin: auto;
		width: 20%;
		background: $gray_light;
		z-index: 2;
		@media screen and (max-width: $mobile) {
			width: 100%;
		}
	}
}

.name:before{
	content: '';
	width: 6px;
	height: 14px;
	background: $prime_color;
	display: inline-block;
	transform: $skew;
	margin: 0 8px -1px 0;
}

.prompt{
	left: 20%;
	position: fixed;
	width: 16%;
	padding: 24px 16px;
	top:84px;
	bottom: 0;
	overflow-x: scroll;
	font-size: 14px;
	line-height: 16px;
	background: #fff;
	border-left: 1px solid $gray_light;
	box-sizing: border-box;
	z-index: 2;
	@media screen and (max-width: $mobile) {
		left:1px;
		width: auto;
		right: 1px;
		top:142px;
		border: none;
	}
	.phones{
		font-size: 12px;
		margin: 16px 0;
		a{
			display: block;
			padding: 4px 0;
			font-weight: bold;
		}
	}
	.address{
		font-size: 12px;
		line-height: 15px;
		background: url(../img/flag.svg) left 2px no-repeat;
		padding-left: 16px;
		margin: 16px 0;
	}
	.contact{
		font-size: 12px;
		line-height: 16px;
		a{
			display: block;
			margin: 4px 0;
		}
	}
	.gallery{
		margin: 24px 0;
		a{
			display: block;
			margin: 2px 0;
			padding: 0;
			img{
				width: 100%;
				display: block;
			}
		}
	}
	.close{
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0;
		top:0;
		cursor: pointer;
		&:after, &:before{
			content: '';
			@extend %center_abs;
			width: 1px;
			height: 14px;
			background: $font_color;
			transform: rotate(45deg);
		}
		&:after{
			transform: rotate(-45deg);
		}
		&:hover{
			transform: scale(1.1);
		}
	}
}

.map{
	background: $font_color;
}
.map_frame{
	position: fixed;
	top:84px;
	bottom: 0;
	left: 20%;
	right: 0;
	@media screen and (max-width: $mobile){
		left: 0;
		top:141px;
	}
}

.point{
	@extend %radius_100;
	position: absolute;
	background: rgba($prime_color, 0.4);
	padding: 10px;
	cursor: pointer;
	b{
		display: block;
		width: 14px;
		height: 14px;
		color: #fff;
		background: $prime_color;
		@extend %radius_100;
		padding: 0px;
		&:after, &:before{
			content: '';
			@extend %center_abs;
			width: 1px;
			height: 7px;
			background: #fff;
		}
		&:before{
			transform: rotate(-90deg);
		}
	}
	&.active{
		padding: 4px;
		b{
			padding: 6px;
		}
	}
}

.ctn_timeline{
	padding: 40px 0 180px;
	@media screen and (max-width: $tablet_ver){
		padding: 40px 0;
	}
	.timeline{
		display: flex;
		font-family: $font_titles;
		font-weight: bold;
		margin-top: 80px;
		counter-reset: section; 
		@media screen and (max-width: $mobile){
			flex-direction: column;
			margin-top: 40px;
			position: relative;
			&:after{
				content: '';
				position: absolute;
				width: 1px;
				top:20px;
				bottom:24px;
				left: 53px;
				background: $prime_color;
			}
		}
		.item{
			width: 16.6666%;
			line-height: 1;
			border-bottom: 1px solid $prime_color;
			padding: 0 40px 20px 0;
			display: flex;
			align-items: flex-end;
			position: relative;
			box-sizing: border-box;
			font-size: 18px;
			@media screen and (max-width: $mobile){
				width: 100%;
				border-bottom: none;
				padding: 16px 0;
				padding-left: 80px;
			}
			&:last-child{
				border-bottom: 0;
			}

			&:before{
				content: '';
				position: absolute;
				left:-3px;
				bottom: -4px;
				background: $prime_color;
				border-radius:100%;
				width: 7px;
				height: 7px;
				@media screen and (max-width: $mobile){
					left: 50px;
					top:-4px;
					bottom: 0;
					margin: auto;
				}
			}
			&:after{
				content: "0" counter(section);
				counter-increment: section;
				position: absolute;
				bottom: -54px;
				font-size: 30px;
				line-height: 1;
				left: -2px;
				@media screen and (max-width: $mobile){
					bottom: 0;
					left:0;
					top:0;
					margin: auto;
					line-height: 1.7;
				}
			}
		}
	}
}

.ctn_testing{
	background: $prime_color;
	color: #fff;
	padding: 90px 0 0;
	position: relative;
	@media screen and (max-width: $mobile){
		padding: 40px 0;
	}
	.into{
		display: flex;
		column-gap: 40px;
		@media screen and (max-width: $mobile){
			flex-direction: column;
			padding-bottom: 190px;
		}
		.left{
			width: 33.333%;
			position: relative;
			@media screen and (max-width: $mobile){
				width: auto;
				position: unset;
			}
		}
	}
	.tdec{
		&:after{
			background: #fff;
		}
		@media screen and (max-width: $mobile){

		}
	}
	ul{
		font-family: $font_titles;
		font-weight: bold;
		line-height: 26px;
		font-size: 24px;
		margin-top: 36px;
		margin-bottom: 90px;
		li{
			&:before{
				background: #fff;
			}
		}
	}
	.dec{
		height: 310px;
		width: 200px;
		transform: $skew;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		left: 70px;
		@media screen and (max-width: $mobile){
			left: 80px;
		}
		img{
			height: 100%;
			left:-34%;
			display: block;
			transform: skew(24deg);
			position: absolute;
		}
		&:after{
			background: #fff;
			width: 3px;
			height: 200px;
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			@media screen and (max-width: $mobile){
			
			}
		}
	}
	.dec_2{
		background: rgba(#fff,0.4);
		transform: $skew;
		bottom: 0;
		height: 60px;
		width: 30px;
		position: absolute;
		right:40%;
		@media screen and (max-width: $mobile){
			right: 32%;
		}
	}
}

table{
	.options{
		input{
			padding: 0 10px;
			font-size: 12px;
			width: 100%;
			box-sizing: border-box;
		}
	}
}











