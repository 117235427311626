.gdpr{
	position: fixed;
	bottom: 0;
	background: $prime_color;
	width: 100%;
	z-index: 5;
	color: $border_color_dark;
	font-size: 14px;
	line-height: 20px;
	animation-name: gdpr;
	animation-duration: 2s;
	display: none;
	
	.content{
		padding: 0px $page_padding;
	}
	p{
		margin:20px 0;
	}
}

@keyframes gdpr{
	0%{
		transform: translateY(100%);
	}
	50%{
		transform: translateY(100%);
	}
}