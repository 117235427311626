.footer{
	background: $prime_color;
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	padding: 20px 0;
	position: relative;
	a{
		color: #fff;
	}
	.into{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media screen and (max-width: $tablet_hor) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.icons{
		display: flex;
		.social_link{
			opacity: 1;
			border: 1px solid $red_light;
			margin-top: 0;
			margin-right: -1px;
			svg{
				fill: #fff;
			}
			
		}
		#drive_link{
			svg{
				width: 54px;
			}
		}
	}
	nav{
		font-size: 11px;
		font-weight: bold;
		padding: 0;
		// margin-left: -8px;
		display: flex;

		a{
			text-transform: uppercase;
			text-decoration: none;
			// padding: 0px 8px;
			padding: 2px 0;
			display: block;
			position: relative;
			margin-right: 20px;
			&:after{
				left: 0;
				content: '';
				bottom: 0;
				height: 1px;
				background: #fff;
				position: absolute;
				transition: $transition_1;
				width: 0;
			}
			&:hover{
				// padding-bottom: 13px;
				&:after{
					width: 100%;
				}
			}
		}
		@media screen and (max-width: $tablet_hor) {
			order: 2;
			column-count:3;
			column-gap:40px;
			display: block;
			margin: 0;
			a{
				padding: 8px 0px;
			}
		}
		@media screen and (max-width: $mobile) {
			column-gap:30px;
		}
	}
	.footer_bot{
		margin: 20px 0 0 0;
	}
	.footer_n{
		text-align: right;
		opacity: 0.8;
		a{
			text-decoration: none;
		}
		@media screen and (max-width: $tablet_hor) {
			margin-top: 16px;
			text-align: left;
		}
	}
	.footer_logo{
		svg{
			fill: #fff;
			height: 18px;
			width: 94px;
		}
		@media screen and (max-width: $tablet_hor) {
			margin: 15px 0;
		}
	}
	.dec{
		width: 2px;
		right:30%;
		background: #fff;
		position: absolute;
		top:0px;
		bottom: 0;
		transform: $skew;
		&:after{
			width: 30px;
			height: 50%;
			content: '';
			background: rgba(#fff, 0.4);
			display: block;
			transform: translateX(10px);
		}
		@media screen and (max-width: $tablet_hor) {
			bottom: auto;
			height: 90px;
			right: auto;
			left: 36%;
		}
	}
}
